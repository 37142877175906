import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

function Error({ setCommon }) {
    useEffect(() => {
      setCommon(false);
    });
  return (
    <>
        <div className="errorPage">
            <h1>404</h1>
            <h3>Page Not Found</h3>
            <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
            <Link to="/">Back to Homepage</Link>
        </div>
    </>
  )
}

export default Error
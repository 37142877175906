import React, { useEffect } from 'react'
import PrivacyPolicy from '../../components/privacy-policy/PrivacyPolicy'

function PrivacyPolicyPage({ setCommon }) {
  useEffect(() => {
    setCommon(true);
  });
  return (
    <>
        <PrivacyPolicy />
    </>
  )
}

export default PrivacyPolicyPage
import React, { useState } from "react";
import Brand from "./brand/Brand";
import Menus from "./menus/Menus";
import TopHeader from "./top-header/TopHeader";
function Header() {
  const [isSticky, setIsSticky] = useState(false);
  
  const fixedHeader = () => {
    if (window.scrollY >= 80) {
      setIsSticky(true)
    }
    else{
      setIsSticky(false);
    }
  }
  window.addEventListener("scroll", fixedHeader);
  return (
    <>
    <header className={`${isSticky ? "sticky active" : "sticky" }`}>
        <TopHeader />
        <Brand />
        <Menus />
      </header>
    </>
  );
}

export default Header;

import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';

function Registration({ setCommon }) {
    useEffect(() => {
      setCommon(false);
    });
    return (
      <>
        <section className="loginPage">
          <form className="loginForm">
            <h1>Registration</h1>
            <div className="loginItem mb-3">
              <label htmlFor="user" className="form-label">
                User Name
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="john smith"
              />
            </div>
            <div className="loginItem mb-3">
              <label htmlFor="email" className="form-label">
                Email address
              </label>
              <input
                type="email"
                className="form-control"
                placeholder="example@gmail.com"
              />
            </div>
            <div className="loginItem mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                placeholder="password"
              />
            </div>
            <div className="loginItem mb-3">
              <label htmlFor="password" className="form-label">
                Confirm Password
              </label>
              <input
                type="password"
                className="form-control"
                placeholder="password"
              />
            </div>
            <div className="loginItem mb-3 form-check">
              <input type="checkbox" className="form-check-input" />
              <label className="form-check-label" htmlFor="exampleCheck1">
              I have read and agree to the <Link to="/">Terms & Conditions</Link>
              </label>
            </div>
            <button type="button" className="btn loginBtn">
            Register 
            </button>
          </form>
        </section>
      </>
    );
  }

export default Registration
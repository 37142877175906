import React, { useEffect } from 'react'
import Contact from '../../components/contact/Contact'

function ContactPage({ setCommon }) {
  useEffect(() => {
    setCommon(true);
  });
  return (
    <>
        <Contact />
    </>
  )
}

export default ContactPage
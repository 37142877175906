import React from "react";
import { FaCommentAlt } from "react-icons/fa";
import { AiFillEye } from "react-icons/ai";
import { Link } from "react-router-dom";

function Politics({ politics }) {
  return (
    <>
      <div className="politicsWrap mb-3">
        <div className="categoryTitle">
          <h3>Politics</h3>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="postInfo">
              {politics.indian.slice(0, 1).map((item) => {
                return (
                  <div className="postItem" key={item.id}>
                    <div className="postFigure">
                      <Link to="/news-detail">
                        <img
                          src={item.url}
                          alt={item.title}
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                    <div className="postText">
                      <h4>
                        <Link to="/news-detail">{item.title}</Link>
                      </h4>
                      <div className="postMeta">
                        <ul>
                          <li className="name">
                            <a href="https://prernamedia.com/profile/prernamedia_admin">
                              {item.name}
                            </a>
                          </li>
                          <li>{item.date}</li>
                          <li className="feedback">
                            <FaCommentAlt />
                            <span>{item.comment}</span>
                          </li>
                          <li className="feedback">
                            <AiFillEye />
                            <span>{item.views}</span>
                          </li>
                        </ul>
                      </div>
                      <p className="desc">{item.desc}</p>
                    </div>
                  </div>
                );
              })}

              {politics.indian.map((item) => {
                return (
                  <div className="postList" key={item.id}>
                    <div className="postListLeft">
                      <Link to="/news-detail">
                        <img
                          src={item.url}
                          alt={item.title}
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                    <div className="postListRight">
                      <h6>
                        <Link to="/news-detail">{item.title}</Link>
                      </h6>
                      <div className="postMeta">
                        <ul>
                          <li>{item.date}</li>
                          <li className="feedback">
                            <FaCommentAlt />
                            <span>{item.comment}</span>
                          </li>
                          <li className="feedback">
                            <AiFillEye />
                            <span>{item.views}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="postInfo">
              {politics.worldWide.slice(0, 1).map((item) => {
                return (
                  <div className="postItem" key={item.id}>
                    <div className="postFigure">
                      <Link to="/news-detail">
                        <img
                          src={item.url}
                          alt={item.title}
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                    <div className="postText">
                      <h4>
                        <Link to="/news-detail">{item.title}</Link>
                      </h4>
                      <div className="postMeta">
                        <ul>
                          <li className="name">
                            <a href="https://prernamedia.com/profile/prernamedia_admin">
                              {item.name}
                            </a>
                          </li>
                          <li>{item.date}</li>
                          <li className="feedback">
                            <FaCommentAlt />
                            <span>{item.comment}</span>
                          </li>
                          <li className="feedback">
                            <AiFillEye />
                            <span>{item.views}</span>
                          </li>
                        </ul>
                      </div>
                      <p className="desc">{item.desc}</p>
                    </div>
                  </div>
                );
              })}

              {politics.worldWide.map((item) => {
                return (
                  <div className="postList" key={item.id}>
                    <div className="postListLeft">
                      <Link to="/news-detail">
                        <img
                          src={item.url}
                          alt={item.title}
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                    <div className="postListRight">
                      <h6>
                        <Link to="/news-detail">{item.title}</Link>
                      </h6>
                      <div className="postMeta">
                        <ul>
                          <li>{item.date}</li>
                          <li className="feedback">
                            <FaCommentAlt />
                            <span>{item.comment}</span>
                          </li>
                          <li className="feedback">
                            <AiFillEye />
                            <span>{item.views}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Politics;

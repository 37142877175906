import React from "react";

function VotingPoll() {
  return (
    <>
      <div className="votingPollSec">
        <div className="categoryTitle">
          <h3>Voting Poll</h3>
        </div>
        <div className="votingCheck">
          <h6>Who is the Best Overseas Admission Consultant In India ?</h6>
          <div className="checkBox">
            <div className="form-check">
                <input
                className="form-check-input"
                type="checkbox"
                defaultValue
                />
                <label className="form-check-label" htmlFor="">
                    Admission Waala
                </label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="checkbox"
                defaultValue
                />
                <label className="form-check-label" htmlFor="">
                www.prernamedia.com
                </label>
            </div>
          </div>
          <div className="button-cnt">
            <button type="button" className="btn btn-success btn-custom">Vote</button>
            <a className="a-view-results">View Results</a>
        </div>

        </div>
      </div>
    </>
  );
}

export default VotingPoll;

import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {FaCommentAlt} from "react-icons/fa";
import {AiFillEye} from "react-icons/ai";
import { Link } from "react-router-dom";

function PopularPosts({data}) {
  return (
    <>
      <div className="popularSec">
        <div className="asideTitle">
          <h4>Popular Posts</h4>
        </div>
        <Tabs
          defaultActiveKey="week"
          id="uncontrolled-tab-example"
          className="popularList"
        >
          <Tab eventKey="week" title="This Week" className="tabContent">
          {data.slice(0,3).map((item)=>{
                return (<div className="postList" key={item.id}>
                <div className="postListLeft">
                  <Link to="/news-detail">
                    <img src={item.url} alt={item.title} className="img-fluid" />
                  </Link>
                </div>
                <div className="postListRight">
                  <h6>
                    <Link to="/news-detail">
                      {item.title}
                    </Link>
                  </h6>
                  <div className="postMeta">
                    <ul>
                        <li>{item.date}</li>
                        <li className="feedback">
                        <FaCommentAlt />
                        <span>{item.comment}</span>
                        </li>
                        <li className="feedback">
                        <AiFillEye />
                        <span>{item.views}</span>
                        </li>
                    </ul>
                    </div>
                </div>
              </div>)
            })}
          </Tab>
          <Tab eventKey="month" title="This Month" className="tabContent">
          {data.slice(1,4).map((item)=>{
                return (<div className="postList" key={item.id}>
                <div className="postListLeft">
                    <Link to="/news-detail">
                      <img src={item.url} alt={item.title} className="img-fluid" />
                    </Link>
                </div>
                <div className="postListRight">
                  <h6>
                    <Link to="/news-detail">
                      {item.title}
                    </Link>
                  </h6>
                  <div className="postMeta">
                    <ul>
                        <li>{item.date}</li>
                        <li className="feedback">
                        <FaCommentAlt />
                        <span>{item.comment}</span>
                        </li>
                        <li className="feedback">
                        <AiFillEye />
                        <span>{item.views}</span>
                        </li>
                    </ul>
                    </div>
                </div>
              </div>)
            })}
          </Tab>
          <Tab eventKey="year" title="This Year" className="tabContent">
          {data.slice(0,4).map((item)=>{
                return (<div className="postList" key={item.id}>
                <div className="postListLeft">
                    <Link to="/news-detail">
                      <img src={item.url} alt={item.title} className="img-fluid" />
                    </Link>
                </div>
                <div className="postListRight">
                  <h6>
                    <Link to="/news-detail">
                      {item.title}
                    </Link>
                  </h6>
                  <div className="postMeta">
                    <ul>
                        <li>{item.date}</li>
                        <li className="feedback">
                        <FaCommentAlt />
                        <span>{item.comment}</span>
                        </li>
                        <li className="feedback">
                        <AiFillEye />
                        <span>{item.views}</span>
                        </li>
                    </ul>
                    </div>
                </div>
              </div>)
            })}
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export default PopularPosts;

import React from "react";

import { FaCommentAlt } from "react-icons/fa";
import { AiFillEye } from "react-icons/ai";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import banner1 from "../../../assets/images/banner/banner1.jpg";
import banner2 from "../../../assets/images/banner/banner2.jpg";
import banner3 from "../../../assets/images/banner/banner3.jpg";
import banner4 from "../../../assets/images/banner/banner4.jpg";
import { Link } from "react-router-dom";
function Banner() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };
  return (
    <>
      <section className="bannerSec">
        <div className="container-fluid">
          <div className="bannerDetail">
            <div className="bannerSlider">
              <Slider {...settings}>
                <figure className="bannerImg">
                  <Link to="/news-detail">
                    <img src={banner1} alt="News" className="img-fluid" />
                    <div className="FigureText">
                      <h3>
                        यूपी: बीते 24 घंटे में प्रदेश के 49 जिलों में नहीं मिला
                        कोरोना का एक भी मरीज, 15 जिलों में सक्रिय केस शून्य
                      </h3>
                      <div className="figureFooter">
                        <div className="admin">
                          <p>admin</p>
                          <p>Jul 15, 2021</p>
                        </div>
                        <div className="comments">
                          <p>
                            <FaCommentAlt />0
                          </p>
                          <p>
                            <AiFillEye /> 77
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="locationNews">
                      <span> वर्ल्ड </span>
                    </div>
                  </Link>
                </figure>
                <figure className="bannerImg">
                  <Link to="/news-detail">
                    <img src={banner3} alt="News" className="img-fluid" />
                    <div className="FigureText">
                      <h3>
                        अफगानिस्तान संकट: 120 से ज्यादा भारतीयों को लेकर उड़ा
                        वायुसेना का विमान, काबुल में बंद होगा भारतीय दूतावास
                      </h3>
                      <div className="figureFooter">
                        <div className="admin">
                          <p>admin</p>
                          <p>Jul 15, 2021</p>
                        </div>
                        <div className="comments">
                          <p>
                            <FaCommentAlt />0
                          </p>
                          <p>
                            <AiFillEye /> 77
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="locationNews">
                      <span> वर्ल्ड </span>
                    </div>
                  </Link>
                </figure>
                <figure className="bannerImg">
                  <Link to="/news-detail">
                    <img src={banner4} alt="News" className="img-fluid" />
                    <div className="FigureText">
                      <h3>
                        राहत: अफगान नागरिकों को आपातकालीन वीजा देगा भारत, दिक्कत
                        दूर करने के लिए खोला नया रास्ता
                      </h3>
                      <div className="figureFooter">
                        <div className="admin">
                          <p>admin</p>
                          <p>Jul 15, 2021</p>
                        </div>
                        <div className="comments">
                          <p>
                            <FaCommentAlt />0
                          </p>
                          <p>
                            <AiFillEye /> 77
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="locationNews">
                      <span> वर्ल्ड </span>
                    </div>
                  </Link>
                </figure>
              </Slider>
            </div>
            <div className="bannerThumbnail">
              <div className="bannerThumbnailInfo">
                <figure className="bannerImg">
                  <Link to="/news-detail">
                    <img src={banner2} alt="News" className="img-fluid" />
                    <div className="FigureText">
                      <h6>
                        अमर उजाला विशेष : तालिबान ने 2020 में ही लिख दी थी
                        अफगानिस्तान में लौटने की पटकथा
                      </h6>
                      <div className="figureFooter">
                        <div className="admin">
                          <p>admin</p>
                          <p>Jul 15, 2021</p>
                        </div>
                        <div className="comments">
                          <p>
                            <FaCommentAlt />0
                          </p>
                          <p>
                            <AiFillEye /> 77
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="locationNews">
                      <span> वर्ल्ड </span>
                    </div>
                  </Link>
                </figure>
              </div>
              <div className="bannerThumbnailInfo">
                <figure className="bannerImg">
                  <Link to="/news-detail">
                    <img src={banner3} alt="News" className="img-fluid" />
                    <div className="FigureText">
                      <h6>
                        अफगानिस्तान संकट: 120 से ज्यादा भारतीयों को लेकर उड़ा
                        वायुसेना का विमान, काबुल में बंद होगा भारतीय दूतावास
                      </h6>
                      <div className="figureFooter">
                        <div className="admin">
                          <p>admin</p>
                          <p>Jul 15, 2021</p>
                        </div>
                        <div className="comments">
                          <p>
                            <FaCommentAlt />0
                          </p>
                          <p>
                            <AiFillEye /> 77
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="locationNews">
                      <span> वर्ल्ड </span>
                    </div>
                  </Link>
                </figure>
              </div>
              <div className="bannerThumbnailInfo">
                <figure className="bannerImg">
                  <Link to="/news-detail">
                    <img src={banner1} alt="News" className="img-fluid" />
                    <div className="FigureText">
                      <h6>
                        यूपी: बीते 24 घंटे में प्रदेश के 49 जिलों में नहीं मिला
                        कोरोना का एक भी मरीज, 15 जिलों में सक्रिय केस शून्य
                      </h6>
                      <div className="figureFooter">
                        <div className="admin">
                          <p>admin</p>
                          <p>Jul 15, 2021</p>
                        </div>
                        <div className="comments">
                          <p>
                            <FaCommentAlt />0
                          </p>
                          <p>
                            <AiFillEye /> 77
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="locationNews">
                      <span> वर्ल्ड </span>
                    </div>
                  </Link>
                </figure>
              </div>
              <div className="bannerThumbnailInfo">
                <figure className="bannerImg">
                  <Link to="/news-detail">
                    <img src={banner4} alt="News" className="img-fluid" />
                    <div className="FigureText">
                      <h6>
                        राहत: अफगान नागरिकों को आपातकालीन वीजा देगा भारत, दिक्कत
                        दूर करने के लिए खोला नया रास्ता
                      </h6>
                      <div className="figureFooter">
                        <div className="admin">
                          <p>admin</p>
                          <p>Jul 15, 2021</p>
                        </div>
                        <div className="comments">
                          <p>
                            <FaCommentAlt />0
                          </p>
                          <p>
                            <AiFillEye /> 77
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="locationNews">
                      <span> वर्ल्ड </span>
                    </div>
                  </Link>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Banner;

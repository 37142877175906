import React from 'react'
import Politics from './politics/Politics'
import Videos from './videos/Videos'

function Category({data}) {
    const {videos, politics} = data;
  return (
    <>
        <Videos videos={videos} />
        <Politics politics={politics} />
    </>
  )
}

export default Category
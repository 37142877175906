import React from "react";
import { MdAddIcCall } from "react-icons/md";

function ContactForm() {
  return (
    <>
      <section className="contactSec">
        <div className="container-fluid">
          <div className="contactInfo">
            <div className="contactDetails">
              <div className="contactAddress">
                <h1 className="ps-3">Contact Us</h1>
                <div className="addressDetail">
                  <div className="addressText">
                    <h5>Head office</h5>
                    <p>
                      Dwarka Sadan, 6 Press Complex, M.P. Nagar, Zone-1, Bhopal,
                      Madhya Pradesh. 462011
                    </p>
                    <h6>
                      <a href="tel:+91 755 473 0000">
                        <MdAddIcCall /> +91 755 473 0000
                      </a>
                    </h6>
                  </div>
                  <div className="addressText">
                    <h5>Corporate office</h5>
                    <p>
                      501, 5th floor, Naman Corporate Link, C-31, G Block,
                      Bandra Kurla Complex, Bandra (E), Mumbai 400 051
                    </p>
                    <h6>
                      <a href="tel:+91 22 71577000">
                        <MdAddIcCall /> +91 22 71577000
                      </a>
                    </h6>
                  </div>
                  <div className="addressText">
                    <h5>Registered office</h5>
                    <p className="mb-1">
                      Plot no. 280, Sarkhej, Gandhinagar Highway, Near YMCA
                      Club, Makarba, Ahmedabad - 380 051, Gujarat.
                    </p>
                    <a href="http://www.prernamedia.com/" target="_blank">
                      www.prernamedia.com
                    </a>
                    <h6>
                      <a href="tel:+91 79 49088888">
                        <MdAddIcCall /> +91 79 49088888
                      </a>
                    </h6>
                  </div>
                  <div class="clear"></div>
                </div>
              </div>
              <div className="contactForm">
                <h6>
                  E-mail your queries to :{" "}
                  <a href="mailto:prernamedia@gmail.in">
                  prernamedia@gmail.in
                  </a>
                </h6>
                <p>All fields are mandatory *</p>
                <form className="row">
                  <div className="mb-3 col-lg-6 col-sm-12">
                    <label htmlFor="firstName" className="form-label">
                      First Name (*)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="John"
                    />
                    
                  </div>
                  <div className="mb-3 col-lg-6 col-sm-12">
                    <label htmlFor="lastName" className="form-label">
                      Last Name (*)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Smith"
                    />
                  </div>
                  <div className="mb-3 col-lg-6 col-sm-12">
                    <label htmlFor="email" className="form-label">
                      Email (*)
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="example@gmail.com"
                    />
                  </div>
                  <div className="mb-3 col-lg-6 col-sm-12">
                    <label htmlFor="mobile" className="form-label">
                      Mobile No. (*)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="10 Digit Number"
                    />
                  </div>

                  <div className="mb-3 col-lg-12 col-sm-12">
                    <label htmlFor="message" className="form-label">
                      Enquiry (*)
                    </label>
                    <textarea
                      class="form-control"
                      rows="3"
                      placeholder="Message..."
                    ></textarea>
                  </div>

                  <div className="col-lg-12">
                    <div className="contactBtn">
                      <button type="button" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactForm;

import React, { useEffect } from 'react'
import News from '../../components/news/News'

function NewsPage({ setCommon }) {
    useEffect(() => {
      setCommon(true);
    });
  return (
    <><News /></>
  )
}

export default NewsPage
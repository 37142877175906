import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { Routes, Route } from "react-router-dom";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import Header from "./components/common/header/Header";
import AboutPage from "./pages/about";
import HomePage from "./pages/home";
import Footer from "./components/common/footer/Footer";
import Login from "./components/login/Login";
import { useState } from "react";
import Registration from "./components/registration/Registration";
import NewsDetailPage from "./pages/news-detail";
import NewsPage from "./pages/news";
import TermsConditionsPage from "./pages/term-of-use";
import PrivacyPolicyPage from "./pages/privacy-policy";
import ContactPage from "./pages/contact";

import Error from "./components/error";
import ThemeColor from "./components/common/theme-color/ThemeColor";
import { BiChevronUp } from "react-icons/bi";


function App() {
  const [common, setCommon] = useState(true);
  const [visible, setVisible] = useState(false);
  const [theme, setTheme] = useState("");
  const handleThemeColor = () => {
    setTheme(!theme)
  }

  // Back to Top Start
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  window.addEventListener("scroll", toggleVisible);
  // Back to Top End

  return (
    <>
    {common && <Header />}
      <Routes>
        <Route path="/" element={<HomePage setCommon={setCommon} />} />
        <Route path="*" element={<Error  setCommon={setCommon}/>} />
        <Route path="/about" element={<AboutPage setCommon={setCommon} />} />
        <Route path="/login" element={<Login setCommon={setCommon} />} />
        <Route path="/registration" element={<Registration setCommon={setCommon} />} />
        <Route path="/news" element={<NewsPage setCommon={setCommon} />} />
        <Route path="/news-detail" element={<NewsDetailPage setCommon={setCommon} />} />
        <Route path="/terms-and-conditions" element={<TermsConditionsPage setCommon={setCommon} />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage setCommon={setCommon} />} />
        <Route path="/contact" element={<ContactPage setCommon={setCommon} />} />
      </Routes>
      {common && <Footer />}
      {common && <ThemeColor theme={theme} handleThemeColor={handleThemeColor} />}

      <div
        className="backTop"
        onClick={scrollToTop}
        style={{ opacity: visible ? "1" : "0" }}
      >
        <BiChevronUp />
      </div>
    </>
  );
}

export default App;

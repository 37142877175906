import React from "react";
import { Link, useParams } from "react-router-dom";

function Breadcrumb(props) {
  const { location, title } = props;
  const params = useParams();
  return (
    <>
      <section className="breadcrumbSec">
        <div className="container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/news">{location}</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/news-detail">{title}</Link>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
}

export default Breadcrumb;

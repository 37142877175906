import React from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../shared/breadcrumb/Breadcrumb";

function PrivacyPolicy() {
  return (
    <>
      <Breadcrumb location="Privacy Policy" />
      <section className="privacySec p-30">
        <div className="container-fluid">
          <div className="privacyInfo">
            <div className="pageTitle m-0">
              <h1 className="">Privacy Policy</h1>
            </div>
            <h4>Objective and Scope</h4>
            <p>
              D B Corp Limited, a company registered under the provisions of
              Thethe Companies Act 1956,
              <strong>acting through its DIGITAL DIVISION </strong>having its
              registered office at Divya prernamedia Press, DB DIGITAL, Ground
              Floor, 280, Makarba Road, Nr. YMCA Club, S.G. Highway,
              Ahmedabad-380051 and corporate office at FC 10 and 11, Sector 16A,
              Film City, Noida – 201301 (“we”, “us”, “our”, “Dainik Prernamedia”,
              “DB”, “DBCL”) values the trust placed in us by data subject
              (“you”, “your”, “user”, “subscriber”) and therefore, we follow the
              highest standards of privacy to protect the information shared by
              you with us.
            </p>
            <p>
              This privacy policy describes the usage of information provided or
              collected by <strong>DBCL</strong>and/or its subsidiary (ies)
              and/or affiliate(s) (collectively referred to as the "
              <strong>Company</strong>"/ “ <strong>DBCL</strong>”), which
              operates various websites/ Mobile applications &amp; other
              services including but not limited to delivery of information and
              content via any mobile or internet connected device or otherwise
              (collectively the " <strong>Services</strong>"). The websites/
              Mobile applications &amp; other services owned and operated by
              DBCL are the sole and exclusive property of DBCL and all the
              rights therein including but not limited to intellectual property
              rights (IPR) therein vests with DBCL at all times. We follow this
              privacy policy in accordance with applicable law in the places
              where we operate. In some cases, we may provide additional data
              privacy notices specific to certain services or regions in order
              to be compliant with applicable law/rules/regulations in force.
              Those terms are to be read in combination with this policy.
            </p>
            <p>
              With a view to offer the most enriching and holistic internet
              experience to its users DBCL offers a vast repository of services.
            </p>
            <p>
              This Privacy Policy should be read in conjunction and together
              with the{" "}
              <a href="/__widgets__/terms-and-conditions">Terms of Use</a>{" "}
              applicable to the DBCL Service that you are using.
            </p>
            <h4>What does this privacy policy cover?</h4>
            <p>
              The objective of the privacy policy is to inform you regarding the
              processing of your personal information which is collected during
              your visit to our website/ mobile application. This policy applies
              to current and former visitors of the website/ mobile application,
              users who register on DBCL in connection with use of our Services,
              or whose information DBCL otherwise receives in connection with
              its Services.
            </p>
            <h4>I. Collection and Use of Personal Information</h4>
            <p>
              Personal information (PI) - means any information relating to an
              identified or identifiable living person (‘data subject’ here is
              referred to as you/your’). In particular using a common identifier
              such as a name, an identification number, location data, an online
              identifier or one or more factors specific to the physical,
              physiological, genetic, mental, economic, cultural or social
              identity of that natural person or any other piece of information
              as per applicable laws and regulations. The Company respects the
              privacy of the users of the Services and is committed to
              reasonably protect it in all respects. The information about the
              user as collected by the Company is:
            </p>
            <h4>a. Information you provide to us</h4>
            <p>Information Collected at the Time of Registration:</p>
            <p>
              When you register using your web accounts like Google we retrieve
              the following Information from your account(s) to continue to
              interact with you and to continue providing you the Services.
              Information that we collect include -
            </p>
            <ul>
              <li>User Name</li>
              <li>E-mail id</li>
              <li>Age</li>
              <li>Gender</li>
              <li>Location</li>
              <li>Profile picture</li>
              <li>Public profile information</li>
            </ul>
            <h4>
              Information Collected when you enroll for e-newspaper (hereinafter
              “e-paper”) subscription / Digital subscription on our platform:
            </h4>
            <p>
              When you enroll for any subscription we may collect the following
              information from you:
            </p>
            <ul>
              <li>Full Name</li>
              <li>Email ID</li>
              <li>Mobile No.</li>
              <li>State</li>
              <li>Country</li>
              <li>IP Address</li>
            </ul>
            <h4>Information Collected when you use our Mobile Application</h4>
            <p>
              When you download our mobile application we collect the below
              information, but not limited to
            </p>
            <ol>
              <li>Device OS, ID &amp; Model</li>
              <li>Device Location</li>
              <li>Contacts access</li>
              <li>Name</li>
              <li>Contact Number</li>
              <li>Date of Birth</li>
              <li>Gender</li>
              <li>Photograph</li>
            </ol>
            <p>
              To add certain content, like pictures, you may allow us to access
              your camera or photo album
            </p>
            <h4>Information you provide to us voluntarily</h4>
            <p>
              We may collect additional information at other times, when you
              provide feedback(s), modify your content or email preferences,
              respond to surveys, participate in contests, or communicate with
              us by posting a comment on our website/ mobile application or
              asking us a question through our e-mail. This information may
              include personal information such as, but not limited to, your
              name, e-mail id, mobile number, comment, postal address etc.
            </p>
            <h4>
              Information Automatically Collected/Tracked while Navigation
            </h4>
            <h4>(a) Cookies</h4>
            <p>
              To improve the responsiveness of the " <strong>Services</strong>"
              for our users, we may use "<strong>cookies</strong>", ( a small
              text file that may be used, for example, to collect your
              information about Website/ mobile application activity. Some
              cookies and other technologies may serve to recall Personal
              Information previously indicated by a user) or similar electronic
              tools to collect information to assign each visitor a unique,
              random number as a User Identification ("<strong>User ID</strong>
              ") to understand the user's individual interests using the
              identified computer. Our advertisers may also assign their own
              cookies to your browser(s) (if you click on their ads), a process
              that we do not control. We receive and store certain types of
              information whenever you interact with us via website(s),
              Application(s) or Services through your computer/laptop/notebook/
              mobile/tablet/pad/handheld device or any other electronic device
              capable of connecting to the internet.
            </p>
            <p>
              You may set most browsers to notify you if you receive a cookie,
              or you may choose to block cookies with your browser, but please
              note that if you choose to erase or block your cookies, you will
              need to re-enter your original user ID and password to gain access
              to certain parts of the Website/ mobile application and some
              sections/features of the site/ application may not work. For more
              details you can refer to our{" "}
              <a href="/__widgets__/subscription-cookies-policy">
                Cookie Policy
              </a>
            </p>
            <h4>(b) Log File Information</h4>
            <p>
              We automatically collect limited information about your computer's
              connection to the Internet, mobile number, including, but not
              limited to, your IP address, browser software ,operating system
              types; clickstream patterns; and dates and times that our site /
              application is accessed, whenever you visit(s) our website(s),
              application or services.
            </p>
            <p>
              We use cookies to personalize content and ads, to provide social
              media features and to analyse our traffic. We also share
              information about your use of our site with our social media,
              advertising and analytics partners who may combine it with other
              information that you’ve provided to them or that they’ve collected
              from your use of their services.
            </p>
            <h4>(c) Inferred Information through usage and log data</h4>
            <p>
              We may collect and track certain information about you based upon
              your behavior on the Services. We use this information for
              conducting internal research on our users' demographics, devices,
              interests, and behavior, in order to better understand, protect
              and serve our users. We may also collect your Information to track
              user behavior and preferences for internal analytics and research.
              We may also use your information:
            </p>
            <ol>
              <li>
                i. To evaluate your interest for various genres of content,
                services, offers;
              </li>
              <li>
                ii. To perform analytics and conduct customer research, to
                determine your interest, for identifying content that generates
                sales and to analyze traffic patterns.
              </li>
            </ol>
            <p>
              If you choose to post messages on social media accounts, message
              boards, chat rooms or other message areas or leave feedback, we
              will collect that information you provide to us. We retain this
              information as necessary to resolve disputes, provide customer
              support and troubleshoot problems as permitted by law.
            </p>
            <p>
              If you send us personal correspondence, such as emails or letters,
              or if other users or third parties send us correspondence about
              your activities or postings on the Services, we may collect and
              store such information.
            </p>
            <h4>(d) Information obtained from other Sources</h4>
            <p>
              We may receive information about you from other online sources. We
              may add it to our account information system and treat it in
              accordance with this policy. If you provide information to the
              platform provider or other partner to whom we provide services,
              your account information including, but not limited to, your name,
              email ID, may be passed on to us. We may obtain updated contact
              information(s) from third parties in order to correct our records
              and fulfill the Services or to communicate with you or share the
              information with any third party. If you link or connect to
              Services with a third party service such as Google, they may send
              us information such as your registered e-mail id and public
              profile information from the service as stated above.
            </p>
            <p>
              In case you choose to decline to submit personal information on
              the Application/Websites, we may not be able to provide certain
              services on the Application/Websites to you. We will make
              reasonable efforts to notify you of the same at the time of
              opening your account. In any case, we will not be liable and or
              responsible for the denial of certain services to you for lack of
              you providing the necessary personal information.
            </p>
            <h4>II. Processing of personal information</h4>
            <p>
              We will only collect and process personal data about you where we
              have a lawful basis. Lawful basis on which we would process your
              personal information includes obtaining explicit consent from you
              for processing your personal information or processing for
              “legitimate interests” where processing is necessary by us to
              provide you with services (For e.g. processing your information by
              our group companies/affiliates).
            </p>
            <ul>
              <li>
                We use information collected from cookies and other
                technologies, like pixel tags, to improve your user experience
                and the overall quality of our services. When showing you
                tailored ads, we will not associate an identifier from cookies
                or similar technologies with sensitive categories, such as those
                based on race, religion, sexual orientation or health.
              </li>
              <li>
                Send alerts and newsletter(s) to you. If you no longer wish to
                receive a specific newsletter, follow the "unsubscribe"
                instructions located near the bottom of each newsletter.
                (Provided you subscribe to the same)
              </li>
              <li>
                Send alerts and newsletter(s) to you. If you no longer wish to
                receive a specific newsletter, follow the "unsubscribe"
                instructions located near the bottom of each newsletter.
                (Provided you subscribe to the same. To unsubscribe, please
                visit your account settings)
              </li>
              <li>
                Send notification(s) to you on your handheld devices (To turn
                off the notifications, please visit your account settings)
              </li>
              <li>
                Communicate with you concerning our service (for example by
                email, push notifications), so that we can send you news about
                DBCL, details about new features and content available on the
                DBCL Services, promotional announcements, marketing purposes and
                surveys, renewal/expiry of services, other services of DBCL
                affiliates and to assist you with operational requests such as
                password reset requests.
              </li>
              <li>
                Improve our website/ mobile application and its content to
                provide better features and services
              </li>
              <li>
                Conducting market research and surveys with the aim of improving
                our products and services
              </li>
              <li>
                Preventing, detecting, investigating and prosecuting crimes
                (including but not limited to fraud and other financial crimes)
                in any jurisdiction, identity verification, government sanctions
                screening and due diligence checks
              </li>
              <li>
                Establishing, exercising or defending legal rights in connection
                with legal proceedings (including any prospective legal
                proceedings) and seeking professional or legal advice in
                relation to such legal proceedings.
              </li>
              <li>
                Our automated systems analyze your content to provide you
                customized search results, recommendations and specific
                promotions and offers
              </li>
              <li>
                To help advertisers understand our audience and confirm the
                value of advertising on our websites or Applications, (however
                it is usually in the form of aggregated statistics on traffic to
                various pages within our site)
              </li>
              <li>
                Notify you about changes in terms of service or privacy policy
              </li>
              <li>
                Allow you to participate in interactive features offered through
                our Services , including contests, news uploads and surveys
              </li>
              <li>
                Sharing with our customer support team to resolve your
                queries/concerns related to your rights or services provided to
                you.
              </li>
            </ul>
            <h4>III. Third party services</h4>
            <p>
              Third parties provide certain services available on Dainik Prernamedia
              on Dainik Prernamedia’s behalf. Dainik Prernamedia may provide
              information, including Personal Information that Dainik Prernamedia
              collects on the Website/ mobile application to third-party service
              providers to help us deliver programs, products, information, and
              services. Service providers are also an important means by which
              Dainik Prernamedia maintains its Website/ mobile application and
              mailing lists. Dainik Prernamedia will take reasonable steps to ensure
              that these third-party service providers are obligated to protect
              Personal Information on Dainik Prernamedia's behalf.
            </p>
            <p>
              Dainik Prernamedia does not intend to transfer Personal Information
              without your consent to third parties who are not bound to act on
              Dainik Prernamedia’s behalf unless such transfer is required for legal
              purposes or for providing relevant services. Similarly, it is
              against ‘Dainik Prernamedia’s’ policy to sell Personal Information
              collected online without consent.
            </p>
            <p>
              When you are in a relationship with Dainik Prernamedia, your personal
              information may be transferred to European Economic Area (EEA) or
              outside EEA, for the purposes mentioned in this policy or to their
              local service providers for support in the pursuance of such
              purposes. Transfers to EEA or outside EEA are covered by standard
              data protection laws. We present information to our advertisers to
              help them understand our audience and confirm the value of
              advertising on our website/ mobile application. It is usually in
              the form of aggregated statistics on traffic to various pages
              within our website/ mobile application. When you register with
              Dainik Prernamedia, we contact you from time to time about updates of
              your content to provide the users such features that we believe
              may benefit you. We use third-party advertising companies to serve
              ads when you visit our website/ mobile application. These
              companies may use information (such as but not limited to your
              name, email, photos/media/files on your device, location, audio
              etc.) about your visits to this and other websites/ applications
              in order to provide advertisements about goods and services of
              interest to you. However, we do not store this data in our
              environment.
            </p>
            <p>
              Please keep in mind that when you provide information to us on a
              third-party site or platform (for example, via our applications
              like social media login the information we collect is through
              those third-party sites linked with our applications is covered by
              this privacy policy, and the information the third-party site or
              platform collects is subject to the third-party site or platform’s
              privacy practices. Privacy choices you have made on the
              third-party site or platform will not apply to our use of the
              information we have collected directly through our applications.
              Please also keep in observance that our sites and applications may
              contain links to other sites not owned or controlled by us and we
              are not responsible for the privacy practices of those sites. We
              encourage you to read the privacy policies of other sites that may
              collect your personal information. All capitalized terms that have
              not been specifically defined herein shall have the same meaning
              as provided under the Terms of Use. Dainik Prernamedia does not
              provide any personal information to the advertiser when you
              interact with or view a targeted ad. However, by interacting with
              an ad you are consenting to the possibility that the advertiser
              will make the assumption that you meet the targeting criteria used
              to display the ad.
            </p>
            <h4>Billing Information</h4>
            <p>
              Based on Your purchase of a subscription package, our payment
              gateway partners including RazorPay Software Private Limited or
              other partner whom we may appoint for the purpose in future may
              collect information with respect to your credit/debit card details
              or other bank account details along with your billing address.
              This information is stored by our payment gateway partners. For
              instance, DBCL will be using third party payment gateway partners
              to process and facilitate the payment of your subscription fee to
              DBCL for providing you access to the subscription. Please note
              that the DBCL does not directly collect any financial information
              such as credit card or debit card or net banking details from you.
              Such payment gateway partners are not controlled by us. When you
              visit such payment gateways you do so at your own risk. These
              payment gateways may have their own privacy policies in place,
              which we recommend you review if you visit any such payment
              gateways. We do not assume any responsibility or liability for
              such payment gateways, the content of such payment gateways and
              their privacy practices, nor do we endorse them. We encourage you
              to familiarize yourself with the privacy statements provided by
              such payment gateways prior to providing them with information
              about you or entering into any transactions with them.
            </p>
            <p>
              While transacting with these payment gateways, you may provide
              your financial information including without limitation your bank
              account details, credit card account details or your details
              pertaining to any payment settlement or pre-paid instrument
              service provider. You understand, agree and acknowledge that DBCL
              never receives your financial and payment information from these
              payment gateways. Your Personal Information, Sensitive Personal
              Data or Information and financial information will be dealt by
              these payment gateways in accordance with their respective privacy
              policies and other terms and conditions and DBCL shall not be
              liable, accountable or responsible for Your Personal Information,
              Sensitive Personal Data and Financial Information which you
              provide to these payment gateways.
            </p>
            <h4>IV. Children</h4>
            <p>
              To use the website/ mobile application you agree that you must
              have attained the minimum age (described in this paragraph below)
              or older.
            </p>
            <p>
              The minimum age for these purposes shall be 16 years, however if
              local laws require that you must be older in order for DBCL to
              lawfully provide the services in the website/ mobile application
              to you then that older age shall apply as the applicable minimum
              age. In all jurisdictions outside the European Union, if you are
              under the age of 18 or the age of majority in your jurisdiction,
              you must use Services under the supervision of your parent, legal
              guardian or responsible adult.
            </p>
            <h4>(V.) Information Sharing</h4>
            <p>
              We restrict access to your Personal Information to employees who
              we believe reasonably need to know/or that information in order to
              fulfill their jobs to provide, operate, develop, or improve our
              products or services. DBCL does not rent, sell, or share personal
              information about you with other people or non-affiliated
              companies except to provide products or services you've requested,
              when we have your permission, or under the following
              circumstances:
            </p>
            <ul>
              <li>
                We provide the information to trusted partners who work on
                behalf of or with ‘DBCL’ under confidentiality agreements. These
                companies may use your personal information to help DBCL
                communicate with you about offers from DBCL and our marketing
                partners. However, these companies do not have any independent
                right to share this information.
              </li>
              <li>
                We respond to subpoenas, court orders, or legal process, or to
                establish or exercise our legal rights or defend against legal
                claims;
              </li>
              <li>
                We believe it is necessary to share information in order to
                investigate, prevent, or take action regarding illegal
                activities, suspected fraud, situations involving potential
                threats to the physical safety of any person, violations of
                ‘DBCL terms of use, or as otherwise required by law.
              </li>
              <li>
                We may transfer information about you if ‘DBCL’ is acquired by
                or merged with another company. In this event, ‘DBCL’ will
                notify you before information about you is transferred and
                becomes subject to a different privacy policy.
              </li>
              <li>
                DBCL works with vendors, partners, advertisers, and other
                service providers in different industries and categories of
                business.
              </li>
            </ul>
            <h4>VI. Retention of personal information</h4>
            <p>
              Your personal information processed by DBCL is kept in a form
              which permits your identification for no longer than is necessary
              for the purposes for which the personal information is processed
              in line with legal, regulatory, contractual or statutory
              obligations as applicable. At the expiry of such periods, your
              personal information will be deleted or archived to comply with
              legal/contractual retention obligations or in accordance with
              applicable statutory limitation periods.
            </p>
            <h4>VII. Monitoring</h4>
            <p>
              To the extent permitted by law, DBCL may record and monitor your
              communications with us to ensure compliance with our legal and
              regulatory obligations and our internal policies. This may include
              the recording of telephone conversations.
            </p>
            <h4>VIII. Your Controls and Choices (As applicable under GDPR)</h4>
            <h4>(a) Accessing and Rectifying your personal information</h4>
            <p>
              When you use the Services on our Website/application (or any of
              its sub sites), we make reasonable efforts to provide you, as and
              when requested by you, with access to your personal information
              and shall further ensure that any personal information or
              sensitive personal data or information found to be inaccurate or
              deficient shall be corrected or amended as feasible. We ask
              individual users to identify themselves and the information
              requested to be accessed or corrected before processing such
              requests. We may decline to process requests that are unreasonably
              repetitive or systematic, requiring disproportionate technical
              effort, jeopardizing the privacy of others, or would be extremely
              impractical (for instance, requests concerning information
              residing on backup tapes), or for which access is not otherwise
              required. In any case, where we provide information access and
              correction, we perform this service free of charge, except if
              doing so would require a disproportionate effort. You can make
              such requests by sending us an e-mail
            </p>
            <h4>(b) Right to Rectification</h4>
            <p>
              You have the right for inaccurate or incomplete data we hold to be
              updated. You shall have the right to obtain from DBCL without
              undue delay the rectification of inaccurate personal information
              concerning you.
            </p>
            <h4>(c) Data Portability</h4>
            <p>
              You may also be entitled to request copies of personal information
              that you have provided to us in a structured, commonly used, and
              machine-readable format and have the right to transmit to another
              controller, wherever feasible
            </p>
            <h4>(d) Data Erasure</h4>
            <p>
              We retain your personal information as long as necessary for us to
              provide services to you or you ask us to not retain your data. If
              you no longer want us to use your information then you can request
              that we erase your personal information and close your DBCL
              Services account. Please note that if you request for the erasure
              of your personal information;
            </p>
            <ul>
              <li>
                We may retain some of your personal information as necessary for
                our legitimate business interests, such as fraud detection and
                prevention and enhancing safety. For example, if we suspend a
                DBCL service account for fraud or safety reasons, we may retain
                certain information from that account to prevent that user from
                opening a new account in the future.
              </li>
              <li>
                We may retain and use your personal information to the extent
                necessary to comply with our legal obligations. For example, we
                may keep some of your information for tax, legal reporting and
                auditing obligations.
              </li>
              <li>
                Information you have shared with others (e.g Reviews, forum
                postings) may continue to be publicly visible on the DBCL
                Services, even after your account is canceled. However,
                attribution of such information to you will be removed.
                Additionally, some copies of your information (e.g., log
                records) may remain in our database, but are disassociated from
                personal identifiers.
              </li>
              <li>
                Because of the way we maintain certain services, after you
                delete your information, residual copies may take a period of
                time before they are deleted from our active servers and may
                remain in our backup systems.
              </li>
            </ul>
            <h4>(e) Withdrawing Consent and Restriction of Processing</h4>
            <p>
              For withdrawing your consent at any time during the tenure of your
              services with us, you may choose to do so by sending us an e-mail.
              We shall review your request and may ask you to verify your
              identity. Post verification we will withdraw the consent for which
              request was made by you and stop any further processing of your
              personal information
            </p>
            <h4>(f) Right to Object to the Processing</h4>
            <p>
              Except in very specific cases where provided by law, you have the
              right to object, on grounds relating to your particular situation,
              at any time to processing of personal data concerning you. Such
              rights can be exercised at any time where your personal data is
              processed for direct marketing purposes.
            </p>
            <h4>
               (g) Right to object being subjected to a decision based solely on
              automated processing, including profiling
            </h4>
            <p>
              You have the right not to be subject to a decision based solely on
              automated processing, including profiling, which produces legal
              effects concerning you or similarly significantly affects you,
              except in specific cases provided by law.
            </p>
            <p>
              DBCL shall provide information on action taken on a request
              pertaining to the rights above without undue delay and in any
              event within one month of receipt of the request. That period may
              be extended by two further months where necessary, taking into
              account the complexity and number of the requests. DBCL shall
              inform the data subject of any such extension within one month of
              receipt of the request, together with the reasons for delay.
            </p>
            <h4>(h) Complaints &amp; Requests</h4>
            <p>
              If you have any complaints regarding processing of your personal
              information or want to access, rectify or delete your data stored
              with us, you can contact our Privacy &amp; Data Officer at{" "}
              <a
                href="mailto:dpo@prernamedia.com?subject=Data Deletion / Updation Request"
                target="_blank"
                rel="noreferrer"
              >
                dpo@prernamedia.com
              </a>
            </p>
            <p>
              You have the right to complain about the data processing
              activities carried out by DBCL before the competent data
              protection authorities.
            </p>
            <h4>IX. Security and Compliance with Laws</h4>
            <p>
              We take appropriate security measures to protect against
              unauthorized access to or unauthorized alteration, disclosure or
              destruction of data. These include internal reviews of our data
              collection, storage and processing practices and security
              measures, including appropriate encryption and physical security
              measures to guard against unauthorized access to systems where we
              store personal data. All information gathered is securely stored
              within the Company controlled database. The database is stored on
              servers secured behind a firewall on the cloud; access to the
              servers is password-protected and is strictly limited. However, as
              effective as our security measures are, no security system is
              impenetrable. If you know or have reason to believe that your DBCL
              Services account credentials have been lost, stolen, altered, or
              otherwise compromised or in case of any actual or suspected
              unauthorized use of your account, please contact us through our
              customer support team.
            </p>
            <h4>X. Social media</h4>
            <p>
              DBCL operates channels, pages and accounts on some social media
              sites to inform, assist and engage with you. DBCL monitors and
              records comments and posts made on these channels about DBCL in
              order to improve its products and services. Please note that you
              must not communicate with DBCL through such social media sites the
              following information:
            </p>
            <ul>
              <li>
                Sensitive personal data including (i) special categories of
                personal data meaning any information revealing racial or ethnic
                origin, political opinions, religious or philosophical beliefs,
                or trade union membership, and the processing of genetic data,
                biometric data for the purpose of uniquely identifying a natural
                person, data concerning health or data concerning a natural
                person's sex life or sexual orientation and (ii) other sensitive
                personal data such as criminal convictions and offences and
                national identification number.
              </li>
              <li>
                Excessive, inappropriate, offensive or insulting information
                towards individuals.
              </li>
            </ul>
            <p>
              DBCL is not responsible for any information posted on those sites
              other than the information posted by its employees on its behalf.
              DBCL is only responsible for its own use of the personal data
              received through such sites
            </p>
            <h4>XI. Changes in the Policy</h4>
            <p>
              DBCL reserves the right to update, change or modify this policy at
              any time. The policy shall come to effect from the date of such
              update, change or modification.
            </p>
            <h4>XII. Contact Information</h4>
            <h4>Support</h4>
            <p>
              If you require any information or clarification regarding the use
              of your personal information or this privacy policy or grievances
              with respect to use of your personal information, please email us
              at "<a href="mailto:dpo@prernamedia.com">dpo@prernamedia.com</a>"
            </p>
            <h4>Postal Address</h4>
            <h4>DB CORP LIMITED (DIGITAL DIVISION)</h4>
            <p>Legal department</p>
            <p>FC 10 and 11, Sector 16A, Film City, Noida – 201301 (U.P.)</p>
            <h4>XIII. Disclaimer</h4>
            <p>
              DBCL shall not be liable for any loss or damage sustained by
              reason of any disclosure (inadvertent or otherwise) of any
              information concerning the user's account and / or information
              relating to or regarding online transactions using credit cards /
              debit cards and / or their verification process and particulars
              nor for any error, omission or inaccuracy with respect to any
              information so disclosed and used whether or not in pursuance of a
              legal process or otherwise.
            </p>
            <p>
              Any other personal information shared by you which is not asked by
              DBCL during registration, either mandatory or optional; accounts
              to wilful and intentional furnishing; and DBCL will not be liable
              for breach of such information.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default PrivacyPolicy;

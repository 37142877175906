import React from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../shared/breadcrumb/Breadcrumb";

function TermsConditions() {
  return (
    <>
      <Breadcrumb location="Terms and Conditions" />
      <section className="termSec p-30">
        <div className="container-fluid">
          <div className="termsInfo">
            <div className="pageTitle">
              <h1>Terms And Conditions</h1>
              <p>
                This Terms of Use, along with the{" "}
                <Link to="/privacy-policy"> Privacy Policy </Link> as available
                on our various websites/ Mobile applications &amp; other
                services (collectively the "<strong>Services</strong>"), is a
                binding agreement between DB Corp Ltd., a company incorporated
                under the provisions of the Companies Act, 1956, having its
                registered office at Plot No. 280, Near YMCA Club,
                Sarkhej-Gandhinagar Highway, Makaraba, Ahmedabad - 480015 and
                corporate office at FC 10 &amp; 11, Sector 16 A, Film City,
                Noida -201301( hereinafter referred to as “DBCL”/ “we”/ “us”/
                “our”/”Company”) and the user (“you”/”User”/”your”). This Terms
                of Use is an electronic record under Information Technology Act,
                2000 and the rules there under as applicable and the amended
                provisions pertaining to electronic records in various statutes
                under applicable Indian laws.
              </p>
            </div>
            <ol>
              <li>
                Your access to the Site is subject to the Terms of Use, as
                modified / updated from time to time entirely at the DBCL
              </li>
              <li>
                You must be 18 years of age or older to visit or use the Site in
                any manner. If you are under the age of 18, you should review
                this Terms of Use with your parent or legal guardian to make
                sure that you and your parent or legal guardian understands and
                agrees to it and further if required you shall perform or
                undertake such activities which will entitle you to enter into a
                legally binding agreement with DBCL. By visiting this Site or
                accepting these Terms of Use, you represent and warrant to DBCL
                that you are 18 years of age or older, and that you have the
                right, authority and capacity to use the Site and agree to and
                abide by these Terms of Use. You also represent and warrant to
                the Company that you will use the Site in a manner consistent
                with any and all applicable laws and regulations.
              </li>
              <li>
                By using the Site, you agree to follow and be bound by all terms
                and conditions concerning your use of the Site. Certain areas of
                the Site may have different terms of use, posted at appropriate
                sections. If there is a conflict between the Terms of Use and
                terms of use posted for a specific area of the Site, the latter
                shall have precedence with respect to your use of that area of
                the Site.
              </li>
              <li>
                You hereby expressly agree to receive communications by way of
                SMSs and/or e-mails and/or Whatsapp and/or Other similar
                channels from DBCL.
              </li>
              <li>
                In some territories, for availing the services under the Site,
                registration by the User is mandatory, while in certain other
                territories the User may or may not have to register for
                availing the services under the Site. DBCL in its sole and
                exclusive discretion may make the registration mandatory in any
                or all of such territories. You will gain the access by using
                the password chosen by you at the time of completing the
                registration on the App/Site by incorporating your personalized
                information being name, email id and mobile no. and the OTP
                password so received on yours email id/ mobile no.. You may also
                visit the site via yours designated account on Facebook and
                Gmail upon which your personalized information will be fetched
                from them and if required additional personalized information
                being name and email id/ mobile no. will have to be incorporated
                by user and the user may be asked to incorporate the OTP for
                verification so received on user email id/ mobile no.. You are
                solely responsible for maintaining the confidentiality of the
                password and account and are fully responsible for all
                activities that occur under your password or account. You may
                change the password on your own at any time. By registering, you
                agree to the following terms in addition to any other specific
                terms which shall be posted at an appropriate location of the
                App/Site. Each registration is for a single individual user only
                for personal use of the App/Site. To access these services, you
                will be asked to enter your individual User Name being your
                mobile no. or via your Google account using which registration
                was completed at first instance. Therefore, we do not permit any
                of the following:-
                <br />
                (a) Any other person sharing your Email/ mobile no. and OTP
                Password.
                <br />
                (b)Any part of the Site being cached in proxy servers and
                accessed by individuals who have not registered with Site as
                users.
                <br />
                (c) Access through a single Email/ mobile no. being made
                available to multiple users on a network.
              </li>
              <li>
                Upon successful registration, users will have to make payment of
                subscription fee for viewing the content for the time period as
                has been specified under each plan of subscription fee. From
                time to time DBCL as per its sole and exclusive discretion may
                provide the option to users for viewing the site content free of
                cost.
              </li>
              <li>
                Free ePaper viewing is for a limited period and for randomly
                selected users. Same can be withdrawn at any time without
                further notice.
              </li>
              <li>
                If we reasonably believe that an Email Id/ mobile no. and
                Password are being used / misused in any manner as stated above,
                we reserve the right to terminate/suspend access rights
                immediately without notice, and block access to all users from
                that IP address.
              </li>
              <li>
                You specifically agree that DBCL shall not be responsible for
                unauthorized access to or alteration of your transmissions or
                data, any material or data sent or received or not sent or
                received through the Site.
              </li>
              <li>
                Where any subscription fee is required to be paid for availing
                the services, You acknowledge that DBCL reserves the
                unrestricted and discretionary right to change, rearrange, add
                or delete services offerings, the selections in those offerings,
                prices, and any other service DBCL may offer, at any time. DBCL
                will endeavor to notify you of any such change and its effective
                date. Unless otherwise specified, subscriptions fees once paid
                are non-refundable, provided that in case of failed transaction
                or duplicate transaction the subscription fees deducted from the
                User’s account shall be refunded in the source account from
                which the payment was made. Subscribed services will
                automatically be canceled once the subscription period is over
                and the subscription fee for renewal is not paid. In the event a
                user has opted and consented for standing instructions for
                automated debit of renewal fee from his bank account/ debit
                card/credit card, the payment gateway providing services to DBCL
                shall deduct the applicable subscription fee for renewal of
                subscription. DBCL uses third party payment gateways for
                collection of credit/debit card or other payment instruments
                wherein your personalized information incorporated at the time
                of registration including the name of billing country will be
                shared. In case of payment gateway failure the same would be
                communicated to you by the payment gateway provider. DBCL shall
                not be responsible for extra currency/conversion
                charges/deductions/error issues from payment gateway. In the
                case of failed online transactions, which result in no services,
                DBCL offers no guarantees whatsoever for the accuracy or
                timeliness of the refunds reaching the customers card/bank
                accounts through the payment gateway.
              </li>
              <li>
                <strong>Place of Supply for GST Purpose:</strong> Place of
                supply will be determined based on the location of the service
                recipient which will be extracted from the IP address of the
                service recipient. In case the location of service recipient is
                outside India, then the place of supply will be determined based
                on the location of the service provider and GST will be charged
                accordingly.
              </li>
              <li>
                Subscription fee may differ from country to country usage.
              </li>
              <li>
                DBCL may in its sole discretion terminate User's access to the
                Site or any part thereof at any time and for any reason without
                any notice. The provisions regarding disclaimer of warranty,
                accuracy of information, software, database other system or
                functionality of Site and indemnification shall survive such
                termination.
              </li>
              <li>DBCL may monitor User’s access to the Site. .</li>
              <li>
                DBCL may, in its sole discretion, and without any obligation or
                liability towards you (including refund or credits for any
                subscription fee whether in full or in part thereof), add or
                delete one or more Content/editions, or any feature of the Site,
                or any of your rights or authorizations at any time, without any
                notice to you. You agree to abide by the changes made under this
                provision. DBCL reserves the right to change the Terms of Use
                applicable to use of the Site. Such changes shall be effective
                immediately upon notice, which shall be placed on the Site.
              </li>
              <li>
                All contents on the Site are the exclusive property of DBCL
                except the third party content which are licensed to DBCL and/or
                its affiliates. The software, text, images, graphics, video and
                audio used on the Site belong to or are licensed to DBCL or its
                subsidiaries and affiliates.
              </li>
              <li>
                No material from the Site may be copied, modified, reproduced,
                republished, uploaded, transmitted, posted or distributed in any
                form without prior written permission from DBCL. All rights not
                expressly granted herein are reserved. Unauthorized use of the
                materials appearing on the Site may violate copyright, trademark
                and other applicable laws, and could result in criminal or civil
                penalties. You are granted a non-exclusive, non-transferable,
                personal, limited right to access the Site and avail the
                services provided by DBCL on the Site only.
              </li>
              <li>
                DBCL does not make any warranties, express or implied, with
                respect to any service, information, data, software, system,
                functionality, statements, content or products made available on
                the Site. The information contained in the Site has been
                obtained from sources believed to be reliable. The Site, and all
                content, materials, information, software and services provided
                on the Site, are provided on an "as is" and "as available"
                basis.
              </li>
              <li>
                DBCL does not warrant that the Site will operate error-free or
                that the Site and its servers are free of computer viruses or
                other harmful mechanisms. If your use of the Site or content
                therein results in the need for servicing or replacing equipment
                or data, DBCL shall not be responsible for any such costs, in
                any manner whatsoever. Site and content are provided on an "as
                is" basis without any warranties of any kind. DBCL to the
                fullest extent permitted by law, disclaims all warranties,
                whether express or implied, including the fitness for particular
                purpose and non-infringement. DBCL makes no warranty about the
                accuracy, reliability, completeness, or timeliness of the
                content, services, software, text, graphics, and links.
              </li>
              <li>
                DBCL shall not be liable for any damage to User's computer
                system or loss of data that result from the download of any
                content, materials, and information from the Site or use of any
                software, systems, functionality, or other services on the Site.
                Under no circumstances will DBCL be liable in any way for any
                content, including, but not limited to, inaccurate, false,
                incomplete, threatening, defamatory, obscene, offensive, illegal
                content, infringement of other’s rights including intellectual
                property rights, for any errors or omissions in any content, or
                for any loss or damage of any kind incurred as a result of the
                use of any content posted or uploaded. Further, under no
                circumstances, shall DBCL be liable for any unlawful act of the
                User or its affiliates, relatives, employees, agents including
                misuse of any data, unfair trade practices, fraud, cyber
                squatting, hacking and other cyber crimes. In no event shall
                DBCL be liable for damages of any kind, including without
                limitation, direct, incidental or consequential damages
                (including, but not limited to, damages for lost profits,
                business interruption and loss of programs or information)
                arising out of the use of or inability to use Site, or any
                information provided thereon, or any claim attributable to
                errors, omissions or other inaccuracies in the Site.
              </li>
              <li>
                You may not send, submit, post, or otherwise transmit, material
                or messages that contain software virus, or any other files that
                are designed to interrupt, destroy, and negatively affect in any
                manner whatsoever, any electronic equipment in connection with
                the use of the Site, or other user's ability to use the Site.
              </li>
              <li>
                User agrees to indemnify and hold DBCL, its subsidiaries,
                affiliates, officers and employees, harmless from any claim,
                demand or damage, including reasonable attorney’s fees, asserted
                by any third party due to or arising out of your use of or
                conduct on the Site. DBCL reserves the right to disclose any
                personal information about you and your use of the Site
                including its contents, without your prior permission, if DBCL
                has a good faith belief that such action is necessary to:
                <br />
                (a) conform to legal requirements or comply with legal process;
                <br />
                (b) protect and defend the rights or property of DBCL or its
                affiliated companies;
                <br />
                (c) enforce the Terms of Use;
                <br />
                (d) act to protect the interest of its members or others.
              </li>
              <li>
                DBCL may, if you so choose, send direct mailers/ sms to you at
                the email address/ mobile no. given by you. You have the option
                to 'opt-out' of this direct mailer by way of links provided at
                the bottom of each mailer. DBCL respects your privacy and in the
                event that you choose to not receive such mailers, DBCL will
                take all necessary steps required to remove you from the list.
              </li>
              <li>
                DBCL recognizes the receipt, transmission or distribution of
                spam emails (unsolicited bulk emails) as a major concern and has
                taken reasonable measures to minimize the transmission and
                effect of spam emails in its computing environment. All emails
                received by DBCL are subject to spam check. Any email identified
                as spam will be rejected with sufficient information to the
                Sender for taking necessary action. With this measure, along
                with other technical spam reduction measures, DBCL hopes to
                minimize the effect of spam emails. DBCL reserves the right to
                reject and/or report any suspicious spam emails, to the
                authorities concerned, for necessary action, from time to time.
              </li>
              <li>
                If the Site provides links to web sites and access to content,
                products and services from third parties, including users,
                advertisers, affiliates and sponsors of the Site, you agree that
                DBCL is not responsible for the availability of, and content
                provided on, third party web sites or content. The User is
                requested to peruse the policies posted by other web sites
                regarding privacy and other terms before use. DBCL is not
                responsible for third party content accessible through the Site,
                including opinions, advice, statements and advertisements, and
                User shall bear all risks associated with the use of such
                content. DBCL is not responsible for any loss or damage of any
                sort which the User may incur from dealing with any third party.
              </li>
              <li>
                By using any third party content, Users may leave the Site and
                be directed to an external website, or to a website maintained
                by an entity other than DBCL and/or its affiliates. If you
                decide to visit any such site, you do so at your own risk and it
                is your responsibility to take all protective measures to guard
                against viruses or any other destructive elements. DBCL makes no
                warranty or representation regarding, and does not endorse, any
                linked web sites or the information appearing thereon or any of
                the products or services described thereon. Links do not imply
                that DBCL or the Site sponsors, endorses, is affiliated or
                associated with, or is legally authorized to use any trademark,
                trade name, logo or copyright symbol displayed in or accessible
                through the links, or that any linked site is authorized to use
                any trademark, trade name, logo or copyright symbol of DBCL or
                any of its affiliates or subsidiaries. You hereby expressly
                acknowledge and agree that the linked sites are not under the
                control of DBCL and DBCL is not responsible for the contents of
                any linked site or any link contained in a linked site, or any
                changes or updates to such sites. DBCL is not responsible for
                webcasting or any other form of transmission received from any
                linked site. DBCL is providing these links to you only as a
                convenience, and the inclusion of any link shall not be
                construed to imply endorsement by DBCL in any manner of the
                website.
              </li>
              <li>
                <strong>Prohibited Activities</strong>
                <br />
                You shall not host, display, upload, modify, publish, transmit,
                update or share any information on the Site, that —<br />
                (a) belongs to another person and to which you do not have any
                right to;
                <br />
                (b) is grossly harmful, harassing, blasphemous, defamatory,
                obscene, pornographic, paedophilic, libellous, invasive of
                another's privacy, hateful, or racially, ethnically
                objectionable, disparaging, relating or encouraging money
                laundering or gambling, or otherwise unlawful in any manner
                whatever;
                <br />
                (c) harm minors in any way;
                <br />
                (d) infringes any patent, trademark, copyright or other
                proprietary rights;
                <br />
                (e) violates any law for the time being in force;
                <br />
                (f) deceives or misleads the addressee about the origin of such
                messages or communicates any information which is grossly
                offensive or menacing in nature;
                <br />
                (g) impersonate another person;
                <br />
                (h) contains software viruses or any other computer code, files
                or programs designed to interrupt, destroy or limit the
                functionality of any computer resource;
                <br />
                (i) threatens the unity, integrity, defence, security or
                sovereignty of India, friendly relations with foreign states, or
                public order or causes incitement to the commission of any
                cognisable offence or prevents investigation of any offence or
                is insulting any other nation;
              </li>
              <li>
                <p>
                  Any Content uploaded by you shall be subject to relevant laws
                  and may disabled, or and may be subject to investigation under
                  appropriate laws. Furthermore, if you are found to be in
                  non-compliance with the laws and regulations, these terms, or
                  the privacy policy of the Site, we may terminate your
                  account/block your access to the Site and we reserve the right
                  to remove any non-compliant Content uploaded by you.Please
                  note that the email address given below is meant for receiving
                  concerns and queries regarding the corresponding Websites /
                  Apps. Concerns / Queries relating to Print Subscription can be
                  sent to the respective city branches.
                </p>
                <p style={{ margin: "10px 0" }}>
                  <strong>
                    Notice of Copyright Infringement and Grievance Redressal:
                  </strong>
                </p>
                <p>
                  DBCL is not liable for any infringement of copyright arising
                  out of materials posted on or transmitted through the Site, or
                  items advertised on the Site, by end users or any other third
                  parties. In the event you have any grievance in relation to
                  any Content uploaded on the Site, you may contact our
                  Grievance Officer, at{" "}
                  <a href="mailto:dpo@prernamedia.com">
                    grievance@dbdigital.in
                  </a>{" "}
                  /{" "}
                  <a href="mailto:dpo@prernamedia.com">
                    dpo@prernamedia.com
                  </a>{" "}
                  or write at the following address:
                </p>
                <br />
                <p>
                  Please note that the email address given below is meant for
                  receiving concerns and queries regarding the corresponding
                  websites/apps. Emails relating to Print Subscription can be
                  sent to the respective city branches.
                </p>
                <br />
                <p>Grievance Redressal Officer</p>
                <br />
                <p>Gaurav Arora</p>
                <br />
                <p>
                  DB CORP LIMITED (DIGITAL DIVISION)
                  <br />
                  FC 10 and 11, Sector 16A, Film city, Noida
                  <br />
                  201301
                </p>
                <br />
                <p>
                  We request you to please provide the following information in
                  your complaint:-
                  <br />
                  (a) A physical or electronic signature of a person authorized
                  to act on behalf of the copyright owner for the purposes of
                  the complaint.
                  <br />
                  (b) Identification of the copyrighted work claimed to have
                  been infringed.
                  <br />
                  (c) Identification of the material on our website that is
                  claimed to be infringing or to be the subject of infringing
                  activity.
                  <br />
                  (d) The address, telephone number or e-mail address of the
                  complaining party.
                  <br />
                  (e) A statement that the complaining party has a good-faith
                  belief that use of the material in the manner complained of is
                  not authorized by the copyright owner, its agent or the law.
                  <br />
                  (f) A statement, under penalty of perjury, that the
                  information in the notice of copyright infringement is
                  accurate, and that the complaining party is authorized to act
                  on behalf of the owner of the right that is allegedly
                  infringed
                </p>
              </li>
              <li>
                <strong>QUESTIONS / DATA CONTROLLER OFFICER </strong>
                <br />
                <p>
                  Redressal Mechanism: Any complaint, abuse or concern with
                  regards to the processing of information provided by you or
                  breach of these terms shall have to be immediately informed to
                  the designated Officer via email signed with the electronic
                  signature at the following email address -{" "}
                  <a href="mailto:dpo@prernamedia.com">
                    dpo@prernamedia.com
                  </a>
                </p>
                <p>
                  We request you to please provide the following information in
                  your complaint:-
                  <br />
                  a) Identification of the information provided by you;
                  <br />
                  b) Clear statement as to whether the information is personal
                  information or sensitive personal information;
                  <br />
                  c) Your address, telephone number or e-mail address;
                  <br />
                  d) A statement that you have a good-faith belief that the
                  information has been processed incorrectly or disclosed
                  without authorization, as the case may be;
                  <br />
                  e) A statement, under penalty of perjury, that the information
                  in the notice is accurate, and that the information being
                  complained about belongs to you;
                  <br />
                  f) You may also contact us in case you have any questions /
                  suggestions about the Privacy Policy using the contact
                  information mentioned above. The company shall not be
                  responsible for any communication, if addressed, to any
                  non-designated person in this regard.
                </p>
              </li>
              <li>
                DBCL reserves the right in its sole discretion to investigate
                and take legal action against anyone who engages in any illegal
                or prohibited conduct or otherwise violates these Terms of Use,
                including without limitation, removing the User content from the
                Site and/or terminating the offending User's ability to access
                the Site and/or use services therein. DBCL may take any other
                action with respect to User content or User actions that it
                deems necessary or appropriate in its sole discretion if it
                believes it may create liability for DBCL or may cause DBCL to
                lose (in whole or in part) the services of its ISPs or other
                suppliers.
              </li>
              <li>
                The User hereby specifically agrees to comply with the
                requirements of the Information Technology Act, 2000 as also
                rules, regulations, guidelines, bye laws and notifications made
                thereunder, while on the Site. The User also undertakes that the
                content provided by him/her is not defamatory, obscene,
                threatening, abusive or hateful. The User shall alone be
                responsible for all his acts, deeds and things and that he alone
                shall be liable for civil and criminal liability there under or
                under any other law for the time being in force.
              </li>
              <li>
                No failure or delay by DBCL in exercising any right, power or
                privilege under the Terms of Use will operate as a waiver of it,
                nor will any single or partial exercise of it preclude any
                further exercise or the exercise of any right, power or
                privilege under the Terms of Use.
              </li>
              <li>
                The Site is controlled, operated and administered by DBCL from
                its offices within India. DBCL makes no representation that
                materials on this Site are appropriate or available for use at
                any other location(s) outside India. Any access to this site
                from territories where their contents are illegal is prohibited.
                You may not use the site or export the materials in violation of
                any applicable export laws and regulations. If you access this
                site from a location outside India, you are responsible for
                compliance with all local laws.
              </li>
              <li>
                The Terms of Use shall be governed by the Laws of India. The
                Courts of law at Noida shall have exclusive jurisdiction over
                any disputes arising under the Terms of Use.
              </li>
              <li>
                The Terms of Use contain the entire understanding and supersedes
                all prior understandings between the Parties relating to the
                subject matter herein and the Terms of Use can be changed by
                DBCL in the manner as provided in this Terms of Use.
              </li>
              <li>
                If any portion of any of the Terms of Use is held invalid or
                unenforceable, that portion will be construed consistent with
                applicable law as nearly as possible, and the remaining portions
                will remain in full force and effect.
              </li>
            </ol>
          </div>
        </div>
      </section>
    </>
  );
}

export default TermsConditions;

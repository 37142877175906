import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../../../../assets/images/logo.png";

function Brand() {
  return (
    <>
        <div className="brandSec">
            <div className="container-fluid">
                <div className="brandLogo">
                    <Link to="/" ><img src={logo} alt="News" className='img-fluid' /></Link>
                </div>
            </div>
        </div>
    </>
  )
}

export default Brand
import React from 'react';
import { FaFacebookF, FaTelegramPlane } from "react-icons/fa";
import { BsTwitter, BsInstagram, BsLinkedin } from "react-icons/bs";

function FollowUs() {
  return (
    <>
        <div className='followUsSec'>
            <div className="asideTitle">
                <h4>Follow Us</h4>
            </div>
            <div className="socialButtons">
                <ul>
                    <li><a href="#" className='facebook'><FaFacebookF  /></a></li>
                    <li><a href="#" className='twitter' ><BsTwitter /></a></li>
                    <li><a href="#" className='instagram'><BsInstagram  /></a></li>
                    <li><a href="#" className='linkedin'><BsLinkedin  /></a></li>
                    <li><a href="#" className='telegram'><FaTelegramPlane  /></a></li>
                </ul>
            </div>
        </div>
    </>
  )
}

export default FollowUs
import React, { useEffect } from 'react'
import Home from '../../components/home/Home'

function HomePage({ setCommon }) {
  useEffect(() => {
    setCommon(true);
  });
  return (
    <>
        <Home />
    </>
  )
}

export default HomePage
import React from "react";
import { FaFacebookF, FaTelegramPlane, FaRegShareSquare } from "react-icons/fa";
import { BsTwitter, BsInstagram, BsLinkedin } from "react-icons/bs";
import { BiRss } from "react-icons/bi";
import { AiFillCalendar, AiOutlineLineChart } from "react-icons/ai";
import { FaLanguage } from "react-icons/fa";
import { Link } from "react-router-dom";

let newDate = new Date();
let date = newDate.getDate();
let month = newDate.getMonth();
let year = newDate.getFullYear();

function TopHeader() {
  return (
    <>
      <div className="topBar">
        <div className="container">
          <div className="topBarInfo">
            <div className="socialIcons">
              <ul>
                <li>
                  <a href="https://www.facebook.com/profile.php?id=100067744910100">
                    <FaFacebookF />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/citynewsindia">
                    <BsTwitter />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/citynews_india/">
                    <BsInstagram />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/in/city-newsindia-846828203">
                    <BsLinkedin />
                  </a>
                </li>
                <li>
                  <a href="https://www.tumblr.com/citynewsindia">
                    <FaTelegramPlane />
                  </a>
                </li>
                <li>
                  <a href="https://prernamedia.com/rss-feeds">
                    <BiRss />
                  </a>
                </li>
              </ul>
            </div>

            <div className="loginSec float-lg-end">
              <ul className="loginInfo">
                <li>
                  <AiFillCalendar />
                    <span className="currentDate">
                     
                    Thursday, March 2, 2023
                    </span>
                </li>
                <li>
                  <AiOutlineLineChart />
                  <span>Advertize</span>
                </li>
                <li>
                  <FaRegShareSquare />
                  <span>Follow</span>
                </li>
                <li className="loginText">
                  <Link to="/login">login</Link>
                  <Link to="/registration">register</Link>
                </li>
              </ul>
              <div className="dropdown languageOption">
                <a
                  className="btn btn-secondary dropdown-toggle"
                  href="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <FaLanguage /> Language
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <li>
                    <a className="dropdown-item" href="#">
                      English
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Hindi
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopHeader;

import React from "react";

import {
  FaFacebookF,
  FaTelegramPlane,
  FaCommentAlt,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
} from "react-icons/fa";
import { BsTwitter, BsInstagram, BsLinkedin } from "react-icons/bs";
import { AiFillEye } from "react-icons/ai";

import like from "../../../assets/images/emoji/like.png";
import angry from "../../../assets/images/emoji/angry.png";
import dislike from "../../../assets/images/emoji/dislike.png";
import funny from "../../../assets/images/emoji/funny.png";
import love from "../../../assets/images/emoji/love.png";
import sad from "../../../assets/images/emoji/sad.png";
import wow from "../../../assets/images/emoji/wow.png";
import RelatedPosts from "./related-posts/RelatedPosts";
import { Link } from "react-router-dom";

function NewsDetailContent({ data }) {
  const { relatedPost } = data;
  return (
    <>
      <div className="newsDetailBody">
        <div className="location">
          <Link to="/news">World</Link>
        </div>

        <div className="pageTitle">
          <h1>
            पेगासस साफ्टवेयर के जरिये मोबाइल फोन में घुसपैठ करते हुए जासूसी करने
            से जुड़ा सच आए सामने
          </h1>
          <p>
            पेगासस प्रोजेक्ट बम ऐसे समय पटका जब संसद का मानसून सत्र आरंभ होना
            था। लिहाजा पिछले सात वर्षो से प्रधानमंत्री नरेंद्र मोदी के नेतृत्व
            वाली सरकार से मुंह की खा रहे विपक्ष को हंगामे का हथियार मिल गया।
          </p>
        </div>

        <div className="pageSocial socialButtons">
          <ul>
            <li>
              <a href="#" className="facebook">
                <FaFacebookF />
              </a>
            </li>
            <li>
              <a href="#" className="twitter">
                <BsTwitter />
              </a>
            </li>
            <li>
              <a href="#" className="instagram">
                <BsInstagram />
              </a>
            </li>
            <li>
              <a href="#" className="linkedin">
                <BsLinkedin />
              </a>
            </li>
            <li>
              <a href="#" className="telegram">
                <FaTelegramPlane />
              </a>
            </li>
          </ul>
        </div>

        <div className="pageFeedback">
          <div className="admin">
            <ul>
              <li>
                <div className="adminText">
                  <Link to="/news-details">
                    <img
                      src="https://prernamedia.com/assets/img/user.png"
                      alt="admin"
                      className="img-fluid"
                    />
                    <span>admin</span>
                  </Link>
                </div>
              </li>
              <li>
                <div className="adminPostDate">
                  <p>Jul 21, 2021 - 13:51</p>
                </div>
              </li>
            </ul>
          </div>
          <div className="views">
            <ul>
              <li className="feedback">
                <FaCommentAlt />
                <span>0</span>
              </li>
              <li className="feedback">
                <AiFillEye />
                <span>23</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="pageDetailText">
          <figure className="pagePostImg">
            <img
              src="https://prernamedia.com/uploads/images/2021/07/image_750x_60f7a0c8554cd.jpg"
              alt="Post"
              className="img-fluid"
            />
          </figure>
          <div className="pagePostPara">
            <p>
              डिजिटल साफ्टवेयर पेगासस के जरिये अनेक देशों की सरकारें अपने
              नागरिकों, विरोधियों व अन्य संदिग्धों की जासूसी करवाती रही हैं।
              दुनिया की 16 मीडिया संस्थानों ने मिलकर इस कथित तथ्य का पर्दाफाश
              करने का दावा किया है। इसके नतीजे पश्चिम के कई प्रमुख अखबारों समेत
              मीडिया पोर्टल पर जारी किए गए हैं। इनमें 40 भारतीयों के नाम भी बताए
              जा रहे हैं, जिनमें सभी नामीगिरामी हस्तियां हैं। यदि वास्तव में इन
              हस्तियों के मोबाइल फोन टेप किए जा रहे थे, तो इसकी सच्चाई जानने के
              लिए इन लोगों को अपने फोन फारेंसिक जांच के लिए देने होंगे। इस तथ्य
              का पर्दाफाश होने से संसद से सड़क तक बेचैनी जरूर है, लेकिन इसके
              परिणाम किसी अंजाम तक पहुंच पाएंगे इसमें संदेह है। दरअसल यह मुखबिरी
              पेगासस के माध्यम से भारत के लोगों पर ही नहीं, बल्कि 40 देशों के 50
              हजार लोगों पर कराई जा रही थी। इस साफ्टवेयर की निर्माता कंपनी एनएसओ
              ने दावा किया है कि इसे वह अपराध और आतंकवाद से लड़ने के लिए केवल
              सरकारों को ही बेचती है। पूर्व आइटी मंत्री रविशंकर प्रसाद ने संसद
              में एनएसओ का पत्र लहराते हुए इस पूरे मामले को नकार दिया। तय है कि
              सरकार ने ऐसा कोई अनाधिकृत काम नहीं किया है, जो निंदनीय हो अथवा
              अभिव्यक्ति की स्वतंत्रता को बाधित करता हो। इसीलिए इस रहस्य से
              पर्दा उठाने वाली फ्रांस की कंपनी फारबिडन स्टोरीज और एमनेस्टी
              इंटरनेशनल ने ऐसे कोई तथ्य नहीं दिए हैं, जो फोन पर की गई बातचीत को
              प्रमाणित करते हों? गोया बिना साक्ष्यों के कथित पर्दाफाश व्यर्थ है।
            </p>
            <p>
              आज लगभग पूरी दुनिया में नीतियां कुछ इस तरह बनाई जा रही हैं कि
              नागरिक डिजिटल तकनीक का उपयोग करें। दूसरी तरफ इंटरनेट मीडिया ऐसा
              ठिकाना है, जिसे व्यक्ति निजी जिज्ञासा पूíत के लिए उपयोग करता है।
              इस दौरान की गई बातचीत पेगासस साफ्टवेयर ही नहीं, कई ऐसे अन्य
              साफ्टवेयर भी हैं जो व्यक्ति की सभी गतिविधियों का क्लोन तैयार कर
              लेने में सक्षम हैं। इसकी खासियत है कि इसके प्रोग्राम को किसी
              स्मार्टफोन में डाल दिया जाए तो यह हैकर का काम करने लगता है।
            </p>
          </div>
        </div>

        <div className="postPagination">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <div className="postPaginationInfo prev">
                <div className="article">
                  <FaAngleDoubleLeft />
                  <span className="articleDirection">Previous Article</span>
                </div>
                <h5 className="title">
                  <a href="https://prernamedia.com/बलय-व-जनपर-म-दषकरम-मनसक-रप-स-बमर-कशर-और-सबज-लकर-लट-रह-नबलग-स-दरदग-थन-क-घरव">
                    बलिया व जौनपुर में दुष्कर्म: मानसिक रूप से बीमार किशोरी और
                    सब्जी लेकर लौट रही नाबालिग...
                  </a>
                </h5>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="postPaginationInfo">
                <div className="article">
                  <span className="articleDirection">Next Article</span>
                  <FaAngleDoubleRight />
                </div>
                <h5 className="title">
                  <a href="https://prernamedia.com/अफगनसतन-तलबन-क-खफ-क-बय-करत-एक-और-तसवर-य-एक-वमन-ह">
                    अफगानिस्तान : तालिबान के खौफ को बयां करती एक और तस्वीर, ये
                    एक विमान है!
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </div>

        <div className="postReaction">
        <div className="categoryTitle"><h3>What's Your Reaction?</h3></div>
          <div className="reactionRate">
            <div className="reactionCard">
              <div className="reactionHeader">
                <img src={like} alt="Reaction" className="img-fluid" />
              </div>
              <div className="reactionBody">
                <span className="number">0</span>
              </div>
              <div className="reactionFooter">
                <button className="btn reactionBtn" type="button">
                  Like
                </button>
              </div>
            </div>
            <div className="reactionCard">
              <div className="reactionHeader">
                <img src={dislike} alt="Reaction" className="img-fluid" />
              </div>
              <div className="reactionBody">
                <span className="number">0</span>
              </div>
              <div className="reactionFooter">
                <button className="btn reactionBtn" type="button">
                  Dislike
                </button>
              </div>
            </div>
            <div className="reactionCard">
              <div className="reactionHeader">
                <img src={love} alt="Reaction" className="img-fluid" />
              </div>
              <div className="reactionBody">
                <span className="number">0</span>
              </div>
              <div className="reactionFooter">
                <button className="btn reactionBtn" type="button">
                  Love
                </button>
              </div>
            </div>
            <div className="reactionCard">
              <div className="reactionHeader">
                <img src={funny} alt="Reaction" className="img-fluid" />
              </div>
              <div className="reactionBody">
                <span className="number">0</span>
              </div>
              <div className="reactionFooter">
                <button className="btn reactionBtn" type="button">
                  Funny
                </button>
              </div>
            </div>
            <div className="reactionCard">
              <div className="reactionHeader">
                <img src={angry} alt="Reaction" className="img-fluid" />
              </div>
              <div className="reactionBody">
                <span className="number">0</span>
              </div>
              <div className="reactionFooter">
                <button className="btn reactionBtn" type="button">
                  Angry
                </button>
              </div>
            </div>
            <div className="reactionCard">
              <div className="reactionHeader">
                <img src={sad} alt="Reaction" className="img-fluid" />
              </div>
              <div className="reactionBody">
                <span className="number">0</span>
              </div>
              <div className="reactionFooter">
                <button className="btn reactionBtn" type="button">
                  Sad
                </button>
              </div>
            </div>
            <div className="reactionCard">
              <div className="reactionHeader">
                <img src={wow} alt="Reaction" className="img-fluid" />
              </div>
              <div className="reactionBody">
                <span className="number">0</span>
              </div>
              <div className="reactionFooter">
                <button className="btn reactionBtn" type="button">
                  Wow
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="aboutAuthor">
          <div className="aboutAuthorLeft">
            <a href="#" className="authorLink">
              <img
                src="https://prernamedia.com/assets/img/user.png"
                alt="Author"
                className="img-responsive imgAuthor"
              />
            </a>
          </div>
          <div className="aboutAuthorRight">
            <div className="aboutAuthorRow">
              <h5>
                <a href="#" className="authorLink">
                  admin
                </a>
              </h5>
            </div>
            <div className="aboutAuthorRow">
              <p>admin</p>
            </div>
          </div>
        </div>

        <RelatedPosts data={relatedPost}/>
        <div className="postComment">
          <div className="categoryTitle">
            <h3>Comments</h3>
          </div>
          <form className="commentsForm row">
            <div className="col-lg-6 col-sm-12">
              <div className="fromGroup mb-3">
                <label htmlFor="email" className="form-label">
                  Enter Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="John Smith"
                />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="fromGroup mb-3">
                <label htmlFor="email" className="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="example@gmail.com"
                />
              </div>
            </div>
            <div className="col-lg-12 col-sm-12">
              <div className="fromGroup mb-3">
                <label htmlFor="email" className="form-label">
                  Comments
                </label>
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  rows="4"
                ></textarea>

              </div>
              <div className="fromGroup mb-3">
              <button type="button" className="btn btn-primary">Post Comment</button>
              </div>
            </div>
          </form>
        </div>

      </div>
    </>
  );
}

export default NewsDetailContent;

import React, { useEffect } from 'react'
import NewsDetail from '../../components/news-detail/NewsDetail'
import Breadcrumb from '../../components/shared/breadcrumb/Breadcrumb';

function NewsDetailPage({ setCommon }) {
  useEffect(() => {
    setCommon(true);
  });
  return (
    <>
      <Breadcrumb location="World" title="पेगासस साफ्टवेयर के जरिये मोबाइल फोन में घुसपैठ करते हुए जासूसी करने से जुड़ा सच आए सामने" />
      <NewsDetail />
    </>
  )
}

export default NewsDetailPage
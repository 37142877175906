import React from "react";
import Breadcrumb from "../shared/breadcrumb/Breadcrumb";

function About(){
  return (
    <>
      <Breadcrumb location="About Us" />
      <section className="aboutSec p-30">
        <div className="container-fluid">
          <div className="aboutInfo">
            <div className="pageTitle m-0">
              <h1>About Us</h1>
              <p>Prerna Media Group is Urban India's #1 newspaper group and has 5 newspapers with 61 editions. The group has a readership of 6.63 crores (Source: TR | IRS 19 Q4, U+R). The group has a strong presence in the radio business. 94.3 MY FM, the radio brand of the group is the largest radio network in India, with presence in 30 cities across 7 states. The digital arm of the group, DB Digital has 4 portals and 3 apps in Hindi, Gujarati and Marathi. Prerna Media Group is Urban India's #1 newspaper group and has 5 newspapers with 61 editions. The group has a readership of 6.63 crores (Source: TR | IRS 19 Q4, U+R). The group has a strong presence in the radio business. 94.3 MY FM, the radio brand of the group is the largest radio network in India, with presence in 30 cities across 7 states. The digital arm of the group, DB Digital has 4 portals and 3 apps in Hindi, Gujarati and Marathi.</p>
              <hr />
              <p>Prerna Media Group is Urban India's #1 newspaper group and has 5 newspapers with 61 editions. The group has a readership of 6.63 crores (Source: TR | IRS 19 Q4, U+R). The group has a strong presence in the radio business. 94.3 MY FM, the radio brand of the group is the largest radio network in India, with presence in 30 cities across 7 states. The digital arm of the group, DB Digital has 4 portals and 3 apps in Hindi, Gujarati and Marathi.</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;

import React from "react";
import { FaCommentAlt } from "react-icons/fa";
import { AiFillEye } from "react-icons/ai";
import { Link } from "react-router-dom";

function PostItem({ data }) {
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
            <div className="pageTitle">
                <h1>VIDEO</h1>
            </div>
        </div>
        {data.map((item) => {
          return (
            <div className="col-lg-6 relatedPostInfo" key={item.id}>
              <div className="relatedPostListImg">
              <Link to="/news-detail">
                  <img src={item.url} alt={item.title} className="img-fluid" />
                </Link>
              </div>
              <div className="relatedPostListText">
                <h6>
                  <Link to="/news-detail">{item.title}</Link>
                </h6>
                <div className="postMeta">
                  <ul>
                    <li>{item.date}</li>
                    <li className="feedback">
                      <FaCommentAlt />
                      <span>{item.comment}</span>
                    </li>
                    <li className="feedback">
                      <AiFillEye />
                      <span>{item.views}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default PostItem;

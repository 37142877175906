import React from "react";
import {FiSettings} from "react-icons/fi";

function ThemeColor({theme, handleThemeColor}) {
  return (
    <>
      <div className={`themeChange ${theme ? "active" : ""}`}>
        <button
          className="btn themeChangeBtn"
          type="submit"
          onClick={handleThemeColor}
        >
          <FiSettings />
        </button>
        <div className="themeColor">
          <ul>
            <li style={{ backgroundColor: "blue" }}></li>
            <li style={{ backgroundColor: "green" }}></li>
            <li style={{ backgroundColor: "orange" }}></li>
            <li style={{ backgroundColor: "black" }}></li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default ThemeColor;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

function BreakingNews() {
  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
  };
  return (
    <>
      <section className="breakingNewsSec p-30">
        <div className="container-fluid">
          <div className="breakingNewsInfo">
            <h5>Breaking News</h5>

            <div className="breakingNewsText">
            <Slider {...settings}>
              <p>
              <Link to="/news-detail">पढ़ें यूपी कैबिनेट के महत्वपूर्ण फैसले : सांसद-विधायक बनाए जाएंगे जिला
                खनिज फाउंडेशन के सदस्य</Link>
              </p>
              <p>
              <Link to="/news-detail">अफगानिस्तान : तालिबान के खौफ को बयां करती एक और तस्वीर, ये एक विमान
                है!</Link>
              </p>
              <p>
              <Link to="/news-detail">राहत: अफगान नागरिकों को आपातकालीन वीजा देगा भारत, दिक्कत दूर करने के
                लिए खोला नया रास्ता</Link>
              </p>
              <p>
              <Link to="/news-detail">अफगानिस्तान संकट: 120 से ज्यादा भारतीयों को लेकर उड़ा वायुसेना का
                विमान, काबुल में बंद होगा भारतीय दूतावास</Link>
              </p>
              <p>
              <Link to="/news-detail">अमर उजाला विशेष : तालिबान ने 2020 में ही लिख दी थी अफगानिस्तान में
                लौटने की पटकथा</Link>
              </p>
            </Slider>             
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BreakingNews;

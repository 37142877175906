import React from "react";
import RelatedPost from "./related-post/RelatedPost";

function RelatedPosts({ data }) {
  return (
    <>
      <div className="relatedPostsSec">
        <div className="categoryTitle">
          <h3>Related Posts</h3>
        </div>
        <div className="row">
            <RelatedPost data={data} />
        </div>
      </div>
    </>
  );
}

export default RelatedPosts;

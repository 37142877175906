import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function Login({ setCommon }) {
  useEffect(() => {
    setCommon(false);
  });
  return (
    <>
      <section className="loginPage">
        <form className="loginForm">
          <h1>Login</h1>
          <div className="loginItem mb-3">
            <label htmlFor="email" className="form-label">
              Email address
            </label>
            <input
              type="email"
              className="form-control"
              placeholder="example@gmail.com"
            />
          </div>
          <div className="loginItem mb-3">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              placeholder="password"
            />
          </div>
          <div className="loginItem mb-3 form-check">
            <input type="checkbox" className="form-check-input" />
            <label className="form-check-label" htmlFor="exampleCheck1">
              Remember Me
            </label>
          </div>
          <button type="button" className="btn loginBtn">
            Login 
          </button>
          <div className="forgot">
            <Link to="/">Forgot Password?</Link>
          </div>
        </form>
      </section>
    </>
  );
}

export default Login;

import React from "react";
import Breadcrumb from "../shared/breadcrumb/Breadcrumb";
import ContactForm from "./contact-form/ContactForm";
function Contact() {
  return (
    <>
      <Breadcrumb location="Contact Us" />
      <ContactForm />      
    </>
  );
}

export default Contact;

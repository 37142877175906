import React, { useState } from 'react';
import {AiFillHome} from "react-icons/ai";
import {BiSearch} from "react-icons/bi";
import {FaCommentAlt} from "react-icons/fa";
import {AiFillEye} from "react-icons/ai";

import { Link } from 'react-router-dom';

const menuData = {
  
  video: [
    {
      id: "1",
      url: "https://img.youtube.com/vi/CVtqk7vUhbI/maxresdefault.jpg",
      title: "Modi Government reshuffle 2023 Know all about...",
      name: "  admin",
      date: "Jul 20, 2023",
      comment: "0",
      views: "27"
    },
    {
      id: "2",
      url: "https://img.youtube.com/vi/UeHCRfLwEYI/maxresdefault.jpg",
      title: "Top 5 best web series on Netflix where you can......",
      name: "  admin",
      date: "Jul 15, 2023",
      comment: "0",
      views: "19"
    },
    {
      id: "3",
      url: "https://img.youtube.com/vi/V2CqIMLwHHI/maxresdefault.jpg",
      title: "Amir khan or Amrita RAO Divorce ki sachai",
      name: "  admin",
      date: "Jul 10, 2023",
      comment: "0",
      views: "66"
    },
    {
      id: "4",
      url: "https://img.youtube.com/vi/qFKcOWUXSVQ/maxresdefault.jpg",
      title: "Top 10 news ",
      name: "  admin",
      date: "Jul 5, 2023",
      comment: "0",
      views: "11"
    },
    {
      id: "5",
      url: "https://img.youtube.com/vi/OPHpZlxdOhY/maxresdefault.jpg",
      title: "बढ़ेंगी सहूलियतें: सभी मेट्रो में होंगे आठ आठ...",
      name: "  admin",
      date: "Jun 30, 2023",
      comment: "0",
      views: "40"
    }
  ],
  politics: [
    {
      id: "1",
      url: "https://img.youtube.com/vi/OPHpZlxdOhY/maxresdefault.jpg",
      title: "बढ़ेंगी सहूलियतें: सभी मेट्रो में होंगे आठ आठ...",
      name: "  admin",
      date: "Jun 30, 2023",
      comment: "0",
      views: "40"
    },{
      id: "2",
      url: "https://img.youtube.com/vi/qFKcOWUXSVQ/maxresdefault.jpg",
      title: "Top 10 news ",
      name: "  admin",
      date: "Jul 5, 2023",
      comment: "0",
      views: "11"
    },
    {
      id: "3",
      url: "https://img.youtube.com/vi/V2CqIMLwHHI/maxresdefault.jpg",
      title: "Amir khan or Amrita RAO Divorce ki sachai",
      name: "  admin",
      date: "Jul 10, 2023",
      comment: "0",
      views: "66"
    },
    {
      id: "4",
      url: "https://img.youtube.com/vi/UeHCRfLwEYI/maxresdefault.jpg",
      title: "Top 5 best web series on Netflix where you can......",
      name: "  admin",
      date: "Jul 15, 2023",
      comment: "0",
      views: "19"
    },
    {
      id: "5",
      url: "https://img.youtube.com/vi/CVtqk7vUhbI/maxresdefault.jpg",
      title: "Modi Government reshuffle 2023 Know all about...",
      name: "  admin",
      date: "Jul 20, 2023",
      comment: "0",
      views: "27"
    }
    
    
  ],
  world:[
    {
      id: "1",
      url: "https://img.youtube.com/vi/CVtqk7vUhbI/maxresdefault.jpg",
      title: "Modi Government reshuffle 2023 Know all about...",
      name: "  admin",
      date: "Jul 20, 2023",
      comment: "0",
      views: "27"
    },
    {
      id: "2",
      url: "https://img.youtube.com/vi/UeHCRfLwEYI/maxresdefault.jpg",
      title: "Top 5 best web series on Netflix where you can......",
      name: "  admin",
      date: "Jul 15, 2023",
      comment: "0",
      views: "19"
    },
    {
      id: "3",
      url: "https://img.youtube.com/vi/V2CqIMLwHHI/maxresdefault.jpg",
      title: "Amir khan or Amrita RAO Divorce ki sachai",
      name: "  admin",
      date: "Jul 10, 2023",
      comment: "0",
      views: "66"
    },
    {
      id: "4",
      url: "https://img.youtube.com/vi/qFKcOWUXSVQ/maxresdefault.jpg",
      title: "Top 10 news ",
      name: "  admin",
      date: "Jul 5, 2023",
      comment: "0",
      views: "11"
    },
    {
      id: "5",
      url: "https://img.youtube.com/vi/OPHpZlxdOhY/maxresdefault.jpg",
      title: "बढ़ेंगी सहूलियतें: सभी मेट्रो में होंगे आठ आठ...",
      name: "  admin",
      date: "Jun 30, 2023",
      comment: "0",
      views: "40"
    }
  ],
  country: [
    {
      id: "1",
      url: "https://img.youtube.com/vi/OPHpZlxdOhY/maxresdefault.jpg",
      title: "बढ़ेंगी सहूलियतें: सभी मेट्रो में होंगे आठ आठ...",
      name: "  admin",
      date: "Jun 30, 2023",
      comment: "0",
      views: "40"
    },{
      id: "2",
      url: "https://img.youtube.com/vi/qFKcOWUXSVQ/maxresdefault.jpg",
      title: "Top 10 news ",
      name: "  admin",
      date: "Jul 5, 2023",
      comment: "0",
      views: "11"
    },
    {
      id: "3",
      url: "https://img.youtube.com/vi/V2CqIMLwHHI/maxresdefault.jpg",
      title: "Amir khan or Amrita RAO Divorce ki sachai",
      name: "  admin",
      date: "Jul 10, 2023",
      comment: "0",
      views: "66"
    },
    {
      id: "4",
      url: "https://img.youtube.com/vi/UeHCRfLwEYI/maxresdefault.jpg",
      title: "Top 5 best web series on Netflix where you can......",
      name: "  admin",
      date: "Jul 15, 2023",
      comment: "0",
      views: "19"
    },
    {
      id: "5",
      url: "https://img.youtube.com/vi/CVtqk7vUhbI/maxresdefault.jpg",
      title: "Modi Government reshuffle 2023 Know all about...",
      name: "  admin",
      date: "Jul 20, 2023",
      comment: "0",
      views: "27"
    }
    
    
  ],
  city: {
    mumbai: ["Mumbai"],
    bangalore: ["Bangalore"],
    hyderabad: ["Hyderabad"],
    ahmadabad: ["Ahmadabad"],
    chennai: ["Chennai"],
    kolkata: ["Kolkata"],
    pune: ["Pune"],
    ranchi: ["Ranchi"],
  }
}

function Menus() {
  const [showSearch, setShowSearch] = useState(false);
  const handleShow = () => {
    setShowSearch(!showSearch)
}
  return (
    <>
   <nav className="navbar navbar-expand-lg menuSec">
  <div className="container-fluid">
    <Link className="navbar-brand" to="/"><AiFillHome /></Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav">
      <span className="navbar-toggler-icon" />
    </button>
    <div className="collapse navbar-collapse menusList" id="main_nav">
      <ul className="navbar-nav">
        <li className=" nav-item dropdown has-megamenu">
          <a className="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown"> videos </a>
          <div className="dropdown-menu megamenu">
            <div className="megaMenuInfo">
              {menuData.video.map((item)=>{
                return (<div className='menusDetail' key={item.id}>
                      <div className="menusBody">
                        <figure className="megaMenuFigure">
                            <img src={item.url} alt={item.name} className="img-fluid" />
                          </figure>
                          <div className="megaMenuText">
                            <h5><Link to="/news-detail">{item.title}</Link></h5>
                            <div className="newsName">
                              <span className='name'>{item.name}</span>
                              <span className='date'>{item.date}</span>
                            </div>
                          </div>
                          
                      </div>
                      <div className="menusFooter">
                        <p className='comments'><FaCommentAlt />{item.comment}</p>
                        <p className='views'><AiFillEye /> {item.views}</p>
                      </div>
                  </div>)
              })}
              </div>
          </div>
        </li>

        <li className=" nav-item dropdown has-megamenu">
          <a className="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown"> politics </a>
          <div className="dropdown-menu megamenu">
          <div className="megaMenuInfo">
              {menuData.politics.map((item)=>{
                return (<div className='menusDetail' key={item.id}>
                      <div className="menusBody">
                        <figure className="megaMenuFigure">
                            <img src={item.url} alt={item.name} className="img-fluid" />
                          </figure>
                          <div className="megaMenuText">
                            <h5><Link to="/news-detail">{item.title}</Link></h5>
                            <div className="newsName">
                              <span className='name'>{item.name}</span>
                              <span className='date'>{item.date}</span>
                            </div>
                          </div>
                          
                      </div>
                      <div className="menusFooter">
                        <p className='comments'><FaCommentAlt />{item.comment}</p>
                        <p className='views'><AiFillEye /> {item.views}</p>
                      </div>
                  </div>)
              })}
              </div>
          </div>
        </li>

        <li className=" nav-item dropdown has-megamenu">
          <a className="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown"> वर्ल्ड </a>
          <div className="dropdown-menu megamenu">
          <div className="megaMenuInfo">
              {menuData.world.map((item)=>{
                return (<div className='menusDetail' key={item.id}>
                      <div className="menusBody">
                        <figure className="megaMenuFigure">
                            <img src={item.url} alt={item.name} className="img-fluid" />
                          </figure>
                          <div className="megaMenuText">
                            <h5><Link to="/news-detail">{item.title}</Link></h5>
                            <div className="newsName">
                              <span className='name'>{item.name}</span>
                              <span className='date'>{item.date}</span>
                            </div>
                          </div>
                          
                      </div>
                      <div className="menusFooter">
                        <p className='comments'><FaCommentAlt />{item.comment}</p>
                        <p className='views'><AiFillEye /> {item.views}</p>
                      </div>
                  </div>)
              })}
              </div>
          </div>
        </li>

        <li className=" nav-item dropdown has-megamenu">
          <a className="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown"> देश </a>
          <div className="dropdown-menu megamenu">
          <div className="megaMenuInfo">
              {menuData.country.map((item)=>{
                return (<div className='menusDetail' key={item.id}>
                      <div className="menusBody">
                        <figure className="megaMenuFigure">
                            <img src={item.url} alt={item.name} className="img-fluid" />
                          </figure>
                          <div className="megaMenuText">
                            <h5><Link to="/news-detail">{item.title}</Link></h5>
                            <div className="newsName">
                              <span className='name'>{item.name}</span>
                              <span className='date'>{item.date}</span>
                            </div>
                          </div>
                          
                      </div>
                      <div className="menusFooter">
                        <p className='comments'><FaCommentAlt />{item.comment}</p>
                        <p className='views'><AiFillEye /> {item.views}</p>
                      </div>
                  </div>)
              })}
              </div>
          </div>
        </li>

        <li className=" nav-item dropdown has-megamenu">
          <a className="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown"> सिटी </a>
          <div className="dropdown-menu megamenu">
          <div className="megaMenuInfo">
            <div className="row w-100">
              <div className="col-lg-2">
                <div className="cityName">
                  <ul>
                    <li className='active'><Link to="/news-detail">mumbai</Link></li>
                    <li><Link to="/news-detail">bangalore</Link></li>
                    <li><Link to="/news-detail">hyderabad</Link></li>
                    <li><Link to="/news-detail">ahmadabad</Link></li>
                    <li><Link to="/news-detail">chennai</Link></li>
                    <li><Link to="/news-detail">kolkata</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="megaMenuInfo">
                 {menuData.country.map((item)=>{
                    return (<div className='menusDetail' key={item.id}>
                            <div className="menusBody">
                              <figure className="megaMenuFigure">
                                  <img src={item.url} alt={item.name} className="img-fluid" />
                                </figure>
                                <div className="megaMenuText">
                                  <h5><Link to="/news-detail">{item.title}</Link></h5>
                                  <div className="newsName">
                                    <span className='name'>{item.name}</span>
                                    <span className='date'>{item.date}</span>
                                  </div>
                                </div>
                                
                            </div>
                            <div className="menusFooter">
                              <p className='comments'><FaCommentAlt />{item.comment}</p>
                              <p className='views'><AiFillEye /> {item.views}</p>
                            </div>
                        </div>)
                      })}
                    </div>
                  </div>
                </div>             
              </div>
          </div>
        </li>

        <li className=" nav-item dropdown has-megamenu">
          <a className="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown"> प्रदेश  </a>
          <div className="dropdown-menu megamenu">
          <div className="megaMenuInfo">
            <div className="row w-100">
              <div className="col-lg-2">
                <div className="cityName">
                  <ul>
                    <li className='active'><Link to="/news-detail">Delhi</Link></li>
                    <li><Link to="/news-detail">Bihar</Link></li>
                    <li><Link to="/news-detail">Jharkhand</Link></li>
                    <li><Link to="/news-detail">Uttar Pardesh</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="megaMenuInfo">
                 {menuData.country.map((item)=>{
                    return (<div className='menusDetail' key={item.id}>
                            <div className="menusBody">
                              <figure className="megaMenuFigure">
                                  <img src={item.url} alt={item.name} className="img-fluid" />
                                </figure>
                                <div className="megaMenuText">
                                  <h5><Link to="/news-detail">{item.title}</Link></h5>
                                  <div className="newsName">
                                    <span className='name'>{item.name}</span>
                                    <span className='date'>{item.date}</span>
                                  </div>
                                </div>
                                
                            </div>
                            <div className="menusFooter">
                              <p className='comments'><FaCommentAlt />{item.comment}</p>
                              <p className='views'><AiFillEye /> {item.views}</p>
                            </div>
                        </div>)
                      })}
                    </div>
                  </div>
                </div>             
              </div>
          </div>
        </li>

        <li className=" nav-item dropdown has-megamenu">
          <a className="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown"> बॉयोस्कोप  </a>
          <div className="dropdown-menu megamenu">
          <div className="megaMenuInfo">
            <div className="row w-100">
              <div className="col-lg-2">
                <div className="cityName">
                  <ul>
                    <li className='active'><Link to="/news-detail">फ़िल्मी गॉसिप</Link></li>
                    <li><Link to="/news-detail">फिल्म समीक्षा</Link></li>
                    <li><Link to="/news-detail">सुर-ताल</Link></li>
                    <li><Link to="/news-detail">हॉलीवुड</Link></li>
                    <li><Link to="/news-detail">स्टार टॉक</Link></li>
                    <li><Link to="/news-detail">छोटा पर्दा</Link></li>
                    <li><Link to="/news-detail">फिल्मिस्तान</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="megaMenuInfo">
                 {menuData.country.map((item)=>{
                    return (<div className='menusDetail' key={item.id}>
                            <div className="menusBody">
                              <figure className="megaMenuFigure">
                                  <img src={item.url} alt={item.name} className="img-fluid" />
                                </figure>
                                <div className="megaMenuText">
                                  <h5><Link to="/news-detail">{item.title}</Link></h5>
                                  <div className="newsName">
                                    <span className='name'>{item.name}</span>
                                    <span className='date'>{item.date}</span>
                                  </div>
                                </div>
                                
                            </div>
                            <div className="menusFooter">
                              <p className='comments'><FaCommentAlt />{item.comment}</p>
                              <p className='views'><AiFillEye /> {item.views}</p>
                            </div>
                        </div>)
                      })}
                    </div>
                  </div>
                </div>             
              </div>
          </div>
        </li>

        <li className=" nav-item dropdown has-megamenu">
          <a className="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown"> खेल  </a>
          <div className="dropdown-menu megamenu">
          <div className="megaMenuInfo">
            <div className="row w-100">
              <div className="col-lg-2">
                <div className="cityName">
                  <ul>
                    <li className='active'><Link to="/news-detail">All</Link></li>
                    <li><Link to="/news-detail">खिलाडी</Link></li>
                    <li><Link to="/news-detail">खेल गॉसिप</Link></li>
                    <li><Link to="/news-detail">खेल समाचार</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="megaMenuInfo">
                 {menuData.country.map((item)=>{
                    return (<div className='menusDetail' key={item.id}>
                            <div className="menusBody">
                              <figure className="megaMenuFigure">
                                  <img src={item.url} alt={item.name} className="img-fluid" />
                                </figure>
                                <div className="megaMenuText">
                                  <h5><Link to="/news-detail">{item.title}</Link></h5>
                                  <div className="newsName">
                                    <span className='name'>{item.name}</span>
                                    <span className='date'>{item.date}</span>
                                  </div>
                                </div>
                                
                            </div>
                            <div className="menusFooter">
                              <p className='comments'><FaCommentAlt />{item.comment}</p>
                              <p className='views'><AiFillEye /> {item.views}</p>
                            </div>
                        </div>)
                      })}
                    </div>
                  </div>
                </div>             
              </div>
          </div>
        </li>

        <li className=" nav-item dropdown has-megamenu">
          <a className="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown"> B&E </a>
          <div className="dropdown-menu megamenu">
          <div className="megaMenuInfo">
            <div className="row w-100">
              <div className="col-lg-2">
                <div className="cityName">
                  <ul>
                    <li className='active'><Link to="/news-detail">All</Link></li>
                    <li><Link to="/news-detail">बैंकिंग और लोन</Link></li>
                    <li><Link to="/news-detail">शेयर मार्केट</Link></li>
                    <li><Link to="/news-detail">प्रॉपर्टी</Link></li>
                    <li><Link to="/news-detail">बचत और निवेश</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="megaMenuInfo">
                 {menuData.country.map((item)=>{
                    return (<div className='menusDetail' key={item.id}>
                            <div className="menusBody">
                              <figure className="megaMenuFigure">
                                  <img src={item.url} alt={item.name} className="img-fluid" />
                                </figure>
                                <div className="megaMenuText">
                                  <h5><Link to="/news-detail">{item.title}</Link></h5>
                                  <div className="newsName">
                                    <span className='name'>{item.name}</span>
                                    <span className='date'>{item.date}</span>
                                  </div>
                                </div>
                                
                            </div>
                            <div className="menusFooter">
                              <p className='comments'><FaCommentAlt />{item.comment}</p>
                              <p className='views'><AiFillEye /> {item.views}</p>
                            </div>
                        </div>)
                      })}
                    </div>
                  </div>
                </div>             
              </div>
          </div>
        </li>

        <li className=" nav-item dropdown has-megamenu">
          <a className="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown"> ज्ञान-विज्ञान  </a>
          <div className="dropdown-menu megamenu">
          <div className="megaMenuInfo">
            <div className="row w-100">
              <div className="col-lg-2">
                <div className="cityName">
                  <ul>
                    <li className='active'><Link to="/news-detail">All</Link></li>
                    <li><Link to="/news-detail">टेक्नोलॉजी न्यूज़</Link></li>
                    <li><Link to="/news-detail">ऑटोमोबाइल</Link></li>
                    <li><Link to="/news-detail">मोबाइल/गैजेट</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="megaMenuInfo">
                 {menuData.country.map((item)=>{
                    return (<div className='menusDetail' key={item.id}>
                            <div className="menusBody">
                              <figure className="megaMenuFigure">
                                  <img src={item.url} alt={item.name} className="img-fluid" />
                                </figure>
                                <div className="megaMenuText">
                                  <h5><Link to="/news-detail">{item.title}</Link></h5>
                                  <div className="newsName">
                                    <span className='name'>{item.name}</span>
                                    <span className='date'>{item.date}</span>
                                  </div>
                                </div>
                                
                            </div>
                            <div className="menusFooter">
                              <p className='comments'><FaCommentAlt />{item.comment}</p>
                              <p className='views'><AiFillEye /> {item.views}</p>
                            </div>
                        </div>)
                      })}
                    </div>
                  </div>
                </div>             
              </div>
          </div>
        </li>

        <li className=" nav-item dropdown has-megamenu">
          <a className="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown"> लाइफस्टाइल  </a>
          <div className="dropdown-menu megamenu">
          <div className="megaMenuInfo">
            <div className="row w-100">
              <div className="col-lg-2">
                <div className="cityName">
                  <ul>
                    <li className='active'><Link to="/news-detail">All</Link></li>
                    <li><Link to="/news-detail">सोशल मीडिया हलचल</Link></li>
                    <li><Link to="/news-detail">अजब-ग़ज़ब</Link></li>
                    <li><Link to="/news-detail">यंगिस्तान</Link></li>
                    <li><Link to="/news-detail">फैशन-सौंदर्य</Link></li>
                    <li><Link to="/news-detail">बल देखबाल</Link></li>
                    <li><Link to="/news-detail">रेसेपी</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="megaMenuInfo">
                 {menuData.country.map((item)=>{
                    return (<div className='menusDetail' key={item.id}>
                            <div className="menusBody">
                              <figure className="megaMenuFigure">
                                  <img src={item.url} alt={item.name} className="img-fluid" />
                                </figure>
                                <div className="megaMenuText">
                                  <h5><Link to="/news-detail">{item.title}</Link></h5>
                                  <div className="newsName">
                                    <span className='name'>{item.name}</span>
                                    <span className='date'>{item.date}</span>
                                  </div>
                                </div>
                                
                            </div>
                            <div className="menusFooter">
                              <p className='comments'><FaCommentAlt />{item.comment}</p>
                              <p className='views'><AiFillEye /> {item.views}</p>
                            </div>
                        </div>)
                      })}
                    </div>
                  </div>
                </div>             
              </div>
          </div>
        </li>

        <li className=" nav-item dropdown has-megamenu">
          <a className="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown"> आस्था  </a>
          <div className="dropdown-menu megamenu">
          <div className="megaMenuInfo">
            <div className="row w-100">
              <div className="col-lg-2">
                <div className="cityName">
                  <ul>
                    <li className='active'><Link to="/news-detail">All</Link></li>
                    <li><Link to="/news-detail">बोलते सितारे</Link></li>
                    <li><Link to="/news-detail">ज्योतिषी</Link></li>
                    <li><Link to="/news-detail">परामनोविज्ञान</Link></li>
                    <li><Link to="/news-detail">रीती-रिवाज</Link></li>
                    <li><Link to="/news-detail">फेंगशुई</Link></li>
                    <li><Link to="/news-detail">धार्मिक स्थान</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="megaMenuInfo">
                 {menuData.country.map((item)=>{
                    return (<div className='menusDetail' key={item.id}>
                            <div className="menusBody">
                              <figure className="megaMenuFigure">
                                  <img src={item.url} alt={item.name} className="img-fluid" />
                                </figure>
                                <div className="megaMenuText">
                                  <h5><Link to="/news-detail">{item.title}</Link></h5>
                                  <div className="newsName">
                                    <span className='name'>{item.name}</span>
                                    <span className='date'>{item.date}</span>
                                  </div>
                                </div>
                                
                            </div>
                            <div className="menusFooter">
                              <p className='comments'><FaCommentAlt />{item.comment}</p>
                              <p className='views'><AiFillEye /> {item.views}</p>
                            </div>
                        </div>)
                      })}
                    </div>
                  </div>
                </div>             
              </div>
          </div>
        </li>

        <li className=" nav-item dropdown has-megamenu">
          <a className="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown"> कैरियर  </a>
          <div className="dropdown-menu megamenu">
          <div className="megaMenuInfo">
            <div className="row w-100">
              <div className="col-lg-2">
                <div className="cityName">
                  <ul>
                    <li className='active'><Link to="/news-detail">All</Link></li>
                    <li><Link to="/news-detail">करंट अफेयर्स</Link></li>
                    <li><Link to="/news-detail">एजुकेशन न्यूज़</Link></li>
                    <li><Link to="/news-detail">GK अपडेट</Link></li>
                    <li><Link to="/news-detail">सक्सेस टिप्स</Link></li>
                    <li><Link to="/news-detail">बौद्धिक-प्रश्न</Link></li>
                    <li><Link to="/news-detail">जॉब जंक्शन</Link></li>
                    <li><Link to="/news-detail">ऐडमिशन-एग्जाम अलर्ट</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="megaMenuInfo">
                 {menuData.country.map((item)=>{
                    return (<div className='menusDetail' key={item.id}>
                            <div className="menusBody">
                              <figure className="megaMenuFigure">
                                  <img src={item.url} alt={item.name} className="img-fluid" />
                                </figure>
                                <div className="megaMenuText">
                                  <h5><Link to="/news-detail">{item.title}</Link></h5>
                                  <div className="newsName">
                                    <span className='name'>{item.name}</span>
                                    <span className='date'>{item.date}</span>
                                  </div>
                                </div>
                                
                            </div>
                            <div className="menusFooter">
                              <p className='comments'><FaCommentAlt />{item.comment}</p>
                              <p className='views'><AiFillEye /> {item.views}</p>
                            </div>
                        </div>)
                      })}
                    </div>
                  </div>
                </div>             
              </div>
          </div>
        </li>

        <li className=" nav-item dropdown has-megamenu">
          <a className="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown"> हेल्थ </a>
          <div className="dropdown-menu megamenu">
          <div className="megaMenuInfo">
            <div className="row w-100">
              <div className="col-lg-2">
                <div className="cityName">
                  <ul>
                    <li className='active'><Link to="/news-detail">All</Link></li>
                    <li><Link to="/news-detail">हेल्दी टिप्स</Link></li>
                    <li><Link to="/news-detail">योग-फ़िटनेस</Link></li>
                    <li><Link to="/news-detail">प्रेग्नेंसी</Link></li>
                    <li><Link to="/news-detail">घरेलू नुस्खे</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="megaMenuInfo">
                 {menuData.country.map((item)=>{
                    return (<div className='menusDetail' key={item.id}>
                            <div className="menusBody">
                              <figure className="megaMenuFigure">
                                  <img src={item.url} alt={item.name} className="img-fluid" />
                                </figure>
                                <div className="megaMenuText">
                                  <h5><Link to="/news-detail">{item.title}</Link></h5>
                                  <div className="newsName">
                                    <span className='name'>{item.name}</span>
                                    <span className='date'>{item.date}</span>
                                  </div>
                                </div>
                                
                            </div>
                            <div className="menusFooter">
                              <p className='comments'><FaCommentAlt />{item.comment}</p>
                              <p className='views'><AiFillEye /> {item.views}</p>
                            </div>
                        </div>)
                      })}
                    </div>
                  </div>
                </div>             
              </div>
          </div>
        </li>

        <li className=" nav-item dropdown has-megamenu">
          <a className="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown"> रसोई   </a>
          <div className="dropdown-menu megamenu">
          <div className="megaMenuInfo">
            <div className="row w-100">
              <div className="col-lg-2">
                <div className="cityName">
                  <ul>
                    <li className='active'><Link to="/news-detail">All</Link></li>
                    <li><Link to="/news-detail">हेल्दी-फ़ूड</Link></li>
                    <li><Link to="/news-detail">राइट डाइट</Link></li>
                    <li><Link to="/news-detail">रसोई टिप्स</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="megaMenuInfo">
                 {menuData.country.map((item)=>{
                    return (<div className='menusDetail' key={item.id}>
                            <div className="menusBody">
                              <figure className="megaMenuFigure">
                                  <img src={item.url} alt={item.name} className="img-fluid" />
                                </figure>
                                <div className="megaMenuText">
                                  <h5><Link to="/news-detail">{item.title}</Link></h5>
                                  <div className="newsName">
                                    <span className='name'>{item.name}</span>
                                    <span className='date'>{item.date}</span>
                                  </div>
                                </div>
                                
                            </div>
                            <div className="menusFooter">
                              <p className='comments'><FaCommentAlt />{item.comment}</p>
                              <p className='views'><AiFillEye /> {item.views}</p>
                            </div>
                        </div>)
                      })}
                    </div>
                  </div>
                </div>             
              </div>
          </div>
        </li>

        <li className=" nav-item dropdown has-megamenu">
          <a className="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown"> business </a>
          <div className="dropdown-menu megamenu">
          <div className="megaMenuInfo">
            <div className="row w-100">
              <div className="col-lg-2">
                <div className="cityName">
                  <ul>
                    <li className='active'><Link to="/news-detail">All</Link></li>
                    <li><Link to="/news-detail">आपकी कलम से</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="megaMenuInfo">
                 {menuData.country.map((item)=>{
                    return (<div className='menusDetail' key={item.id}>
                            <div className="menusBody">
                              <figure className="megaMenuFigure">
                                  <img src={item.url} alt={item.name} className="img-fluid" />
                                </figure>
                                <div className="megaMenuText">
                                  <h5><Link to="/news-detail">{item.title}</Link></h5>
                                  <div className="newsName">
                                    <span className='name'>{item.name}</span>
                                    <span className='date'>{item.date}</span>
                                  </div>
                                </div>
                                
                            </div>
                            <div className="menusFooter">
                              <p className='comments'><FaCommentAlt />{item.comment}</p>
                              <p className='views'><AiFillEye /> {item.views}</p>
                            </div>
                        </div>)
                      })}
                    </div>
                  </div>
                </div>             
              </div>
          </div>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/news-detail" > funding  </Link>
        </li>
      </ul>

      <div className="searchBox ms-auto">
        <div className="searchIcon">
          <BiSearch onClick={handleShow} />
          {showSearch && (<div className="searchItem">
            <div className="searchWrap">
              <div className="searchElm">
                <input type="text" placeholder='Search... ' className='form-control' />
                <button type='button' className='btn'><BiSearch /></button>
              </div>
            </div>
          </div>) }
        </div>
      </div>
    </div> {/* navbar-collapse.// */}
  </div> {/* container-fluid.// */}
</nav>

    </>
  )
}

export default Menus
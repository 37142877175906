import React, { useEffect } from 'react'
import About from '../../components/about/About';

function AboutPage({ setCommon }) {
  useEffect(() => {
    setCommon(true);
  });
  return (
    <>
      <About />
    </>
  )
}

export default AboutPage;
import React from 'react';
import { FaCommentAlt } from "react-icons/fa";
import { AiFillEye } from "react-icons/ai";
import { Link } from 'react-router-dom';

function RelatedPost({data}) {
  return (
    <>
        {data.map((item) => {
          return (
            <div className="col-lg-4 col-sm-6 relatedPostInfo" key={item.id}>
              <div className="relatedPostListImg">
              <Link to="/news-detail">
                  <img src={item.url} alt={item.title} className="img-fluid" />
                </Link>
              </div>
              <div className="relatedPostListText">
                <h6>
                <Link to="/news-detail">{item.title}</Link>
                </h6>
                <div className="postMeta">
                  <ul>
                    <li>{item.date}</li>
                    <li className="feedback">
                      <FaCommentAlt />
                      <span>{item.comment}</span>
                    </li>
                    <li className="feedback">
                      <AiFillEye />
                      <span>{item.views}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          );
        })}
    </>
  )
}

export default RelatedPost
import React from 'react'
import Aside from '../home/aside/Aside';
import NewsDetailContent from './news-detail-content/NewsDetailContent';
const data = {
    videos: {
      indian: [
        {
          id: "1",
          url: "https://prernamedia.com/uploads/images/2021/08/image_380x226_6117664865714.jpg",
          title:
            "Modi government reshuffle 2021 know all about new big...",
          name: "  admin",
          date: "Jul 20, 2023",
          comment: "0",
          views: "27",
        },
        {
          id: "2",
          url: "https://img.youtube.com/vi/OPHpZlxdOhY/maxresdefault.jpg",
          title: "बढ़ेंगी सहूलियतें: सभी मेट्रो में होंगे आठ आठ कोच, भीड़...",
          name: "  admin",
          date: "Jul 15, 2023",
          comment: "0",
          views: "19",
        },
        {
          id: "3",
          url: "https://img.youtube.com/vi/SINm2jDSNHE/maxresdefault.jpg",
          title:
            "International Yoga Day 2021 Theme: जानेंहर साल 21 जनू...",
          name: "  admin",
          date: "Jul 10, 2023",
          comment: "0",
          views: "66",
        },
      ],
      worldWide: [
        {
          id: "1",
          url: "https://img.youtube.com/vi/UeHCRfLwEYI/maxresdefault.jpg",
          title:
            "top 5 best web series on netflix where you can find your mind mess",
          name: "  admin",
          date: "Jul 20, 2023",
          comment: "0",
          views: "27",
        },
        {
          id: "2",
          url: "https://img.youtube.com/vi/qFKcOWUXSVQ/maxresdefault.jpg",
          title: "top 10 news",
          name: "  admin",
          date: "Jul 15, 2023",
          comment: "0",
          views: "19",
        },
        {
          id: "3",
          url: "https://img.youtube.com/vi/5pm7j0qui6k/maxresdefault.jpg",
          title:
            "top 10 crime web series",
          name: "  admin",
          date: "Jul 10, 2023",
          comment: "0",
          views: "66",
        }
        
      ],
    },
    politics: {
      indian: [
        {
          id: "1",
          url: "https://prernamedia.com/uploads/images/2021/08/image_380x226_611b3792c4a69.jpg",
          title:
            "पाकिस्‍तान के लिए भी मुसीबत बन सकता तालिबान का एक धड़ा...",
          desc: "अफगानिस्तान पर तालिबान का कब्जा हो चुका है और जल्द ही यह आतंकी संगठन वहां अपनी सरकार...",
          name: "  admin",
          date: "Jul 20, 2023",
          comment: "0",
          views: "27",
        },
        {
          id: "2",
          url: "https://prernamedia.com/uploads/images/2021/08/image_140x98_6117521ad01aa.jpg",
          title: "राज्यसभा की अंतरिम सुरक्षा रिपोर्ट में कुछ विपक्षी सांसदों...",
          name: "  admin",
          date: "Jul 15, 2023",
          comment: "0",
          views: "19",
        },
        {
          id: "3",
          url: "https://prernamedia.com/uploads/images/2021/08/image_140x98_6115f62196645.jpg",
          title:
            "संसद का पूरा मानसून सत्र विपक्षी हंगामे की भेंट चढ़ गया...",
          name: "  admin",
          date: "Jul 10, 2023",
          comment: "0",
          views: "66",
        },
      ],
      worldWide: [
        {
          id: "1",
          url: "https://prernamedia.com/uploads/images/2021/08/image_380x226_611b372b3eaab.jpg",
          title:
            "सुरक्षा परिषद की बैठक में भारत बोला- अफगानिस्तान में शांति के लिए UNSC",
          desc: "अफगानिस्तान के हालात को लेकर सोमवार को भारत की अध्यक्षता में एक बार फिर संयुक्त...",
          name: "  admin",
          date: "Jul 20, 2023",
          comment: "0",
          views: "27",
        },
        {
          id: "2",
          url: "https://prernamedia.com/uploads/images/2021/08/image_140x98_611751d0843c7.jpg",
          title: "राज्यसभा की अंतरिम सुरक्षा रिपोर्ट में कुछ विपक्षी सांसदों...",
          name: "  admin",
          date: "Jul 15, 2023",
          comment: "0",
          views: "19",
        },
        {
          id: "3",
          url: "https://prernamedia.com/uploads/images/2021/08/image_140x98_6115f5ae2ed94.jpg",
          title:
            "संसद का पूरा मानसून सत्र विपक्षी हंगामे की भेंट चढ़ गया...",
          name: "  admin",
          date: "Jul 10, 2023",
          comment: "0",
          views: "66",
        }
        
      ],
    },
    popularPost : [
      {
        id: "1",
        url: "https://prernamedia.com/uploads/images/2021/08/image_380x226_6117664865714.jpg",
        title:
          "Modi-government-reshuffle-2021-know-all-about-new-big-changes-polity-current-affairs-upsc",
        name: "  admin",
        date: "Jul 20, 2023",
        comment: "0",
        views: "27",
      },
      {
        id: "2",
        url: "https://prernamedia.com/uploads/images/2021/08/image_140x98_611b485e38369.jpg",
        title: "पुल निर्माण न होने पर ग्रामीणों ने भाजपा नेताओं को बनाया...",
        name: "  admin",
        date: "Jul 15, 2023",
        comment: "0",
        views: "19",
      },
      {
        id: "3",
        url: "https://prernamedia.com/uploads/images/2021/08/image_140x98_611b379301f79.jpg",
        title:
          "पाकिस्‍तान के लिए भी मुसीबत बन सकता तालिबान का एक धड़ा,...",
        name: "  admin",
        date: "Jul 10, 2023",
        comment: "0",
        views: "66",
      },
      {
        id: "4",
        url: "https://prernamedia.com/uploads/images/2021/08/image_140x98_611b4a2149e12.jpg",
        title:
          "अफगानिस्तान संकट: 120 से ज्यादा भारतीयों को लेकर उड़ा वायुसेना...",
        name: "  admin",
        date: "Jul 10, 2023",
        comment: "0",
        views: "66",
      }
    ],
    relatedPost : [
      {
        id: "1",
        url: "https://prernamedia.com/uploads/images/2021/01/image_380x226_60112ed75f631.jpg",
        title:
          "बेटियों की हत्या करने वाले माता-पिता गिरफ्तार, पुलिस के ...",
        name: "  admin",
        date: "Jul 20, 2023",
        comment: "0",
        views: "27",
      },
      {
        id: "2",
        url: "https://prernamedia.com/uploads/images/2021/04/image_380x226_608a335d227a8.jpg",
        title: "कोरोना संकट: राजधानी में हाहाकार पर आरएसएस का सवाल, ट्वीट...",
        name: "  admin",
        date: "Jul 15, 2023",
        comment: "0",
        views: "19",
      },
      {
        id: "3",
        url: "https://prernamedia.com/uploads/images/2021/06/image_380x226_60dbfe3465122.jpg",
        title:
          "Monsoon Latest Update: दिल्ली-MP-UP सहित इन राज्यों में...",
        name: "  admin",
        date: "Jul 10, 2023",
        comment: "0",
        views: "66",
      },
      {
        id: "4",
        url: "https://prernamedia.com/uploads/images/2021/02/image_380x226_60260e7a76af3.jpg",
        title:
          "देश में पिछले 24 घंटों में कोरोना के 10 हजार से कम मामले...",
        name: "  admin",
        date: "Jul 10, 2023",
        comment: "0",
        views: "66",
      },
      {
        id: "5",
        url: "https://prernamedia.com/uploads/images/2021/01/image_380x226_600512972844e.jpg",
        title:
          "24 घंटे में 13788 नए मामले, 14457 मरीज हुए ठीक ",
        name: "  admin",
        date: "Jul 10, 2023",
        comment: "0",
        views: "66",
      },
      {
        id: "5",
        url: "https://prernamedia.com/uploads/images/2021/08/image_380x226_6107961dc507a.jpg",
        title:
          "UP Rain Update: यूपी के इन 8 जिलों में भारी बारिश का अलर्ट, कई जिलों में बाढ़ का भी खतरा ",
        name: "  admin",
        date: "Jul 10, 2023",
        comment: "0",
        views: "66",
      }
    ]
  };

function NewsDetail() {
  return (
    <>
        <section className='pageWrapper p-30'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-8">
                        <NewsDetailContent data={data}  />
                    </div>
                    <div className="col-lg-4"><Aside data={data} /></div>
                </div>
            </div>
        </section>
    </>
  )
}

export default NewsDetail
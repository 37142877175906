import React, { useEffect } from 'react'
import TermsConditions from '../../components/terms-and-conditions/TermsConditions';

function TermsConditionsPage({ setCommon }) {
  useEffect(() => {
    setCommon(true);
  });
  return (
    <>
        <TermsConditions />
    </>
  )
}

export default TermsConditionsPage;
import React from "react";

function PostTags() {
  return (
    <>
      <div className="postTags mt-5">
        <div className="asideTitle">
          <h4>Tags</h4>
        </div>
        <div className="tagWidgets">
          <ul className="tag-list">
            {/*List tags*/}
            <li>
              <a href="https://prernamedia.com/tag/ranchi-common-man-issues-news-state-jharkhand-weather-news-jharkhand-weather-report-jharkhand-weather-update-weather-news-weather-update-jharkhand-weather-update-ranchi-news-jharkhand-news-झरखड-नयज-रच-समचर-मसम-समचर-news-national-news-jharkhand-news">
                 #ranchi-common-man-issues #news #state #Jharkhand Weather News#
                Jharkhand Weather Report #Jharkhand Weather Update #Weather
                news #Weather update #Jharkhand Weather update #Ranchi News#
                Jharkhand news #झारखंड न्यूज #रांची समाचार #मौसम समाचार #News#
                Nation{" "}
              </a>
            </li>
            <li>
              <a href="https://prernamedia.com/tag/lockdown">#Lockdown </a>
            </li>
            <li>
              <a href="https://prernamedia.com/tag/patna-city-crime-news-state-bihar-news-bihar-top-state-top-man-ate-snake-man-bites-and-kills-snake-सप-क-खय-सप-क-ख-गय-बजरग-नलद-म-सप-क-चबय-news-national-news-bihar-news">
                 #patna-city-crime #news #state #Bihar News #Bihar Top #State
                Top #man ate snake #man bites and kills snake #सांप को खाया#
                सांप को खा गया बुजुर्ग #नालंदा में सांप को चबाया #News #National
                News #Bihar news{" "}
              </a>
            </li>
            
          </ul>
        </div>
      </div>
    </>
  );
}

export default PostTags;

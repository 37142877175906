import React from "react";
import FollowUs from "./follow-us/FollowUs";
import PopularPosts from "./popular-posts/PopularPosts";
import PostTags from "./post-tags/PostTags";
import RecommendedPost from "./recommended-post/RecommendedPost";
import VotingPoll from "./voting-poll/VotingPoll";

function Aside({ data }) {
  const { popularPost } = data;
  return (
    <>
      <PopularPosts data={popularPost} />
      <FollowUs />
      {/* <RecommendedPost /> */}
      <PostTags />
      <VotingPoll />
    </>
  );
}

export default Aside;
